import React from 'react';

import { PaletteWrapper } from './Base/PaletteWrapper';
import {
  AccountIssueAction,
  BugReportAction,
  FeatureRequestAction,
  ProductQuestionAction,
  ReportParsingFailAction,
} from './GeneralPalette';


export const FeedbackPalette = (): JSX.Element => {
  return <PaletteWrapper hasInput={false} title="What would you like to report?">
    <ProductQuestionAction
      focused={false} label="Product question"
      tags={['bug', 'report', 'product', 'question', 'feature', 'improve', 'request']} />
    <AccountIssueAction
      focused={false} label="Account/billing issue"
      tags={['bug', 'report', 'account', 'billing', 'question', 'payment']} />
    <ReportParsingFailAction
      focused={false} label="feedback report parsing issue"
      tags={['bug', 'report', 'parsing', 'issue', 'feedback', 'provide']} />
    <BugReportAction
      focused={false} label="feedback bug report"
      tags={['bug', 'report', 'parsing', 'issue', 'feedback', 'feature', 'request', 'provide']} />
    <FeatureRequestAction
      focused={false} label="feedback suggest improvement"
      tags={['bug', 'report', 'parsing', 'issue', 'feedback', 'feature', 'request', 'provide']} />
  </PaletteWrapper>;
};

