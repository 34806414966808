import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import { messageCopy } from '../../../../shared/foreground/messageCopy';
import { globalState } from '../../../../shared/foreground/models';
import background from '../../../../shared/foreground/portalGates/toBackground/singleProcess';
import { usePartialDocument, useRssFeeds } from '../../../../shared/foreground/stateHooks';
import useRSSFeedIdByUrl from '../../../../shared/foreground/stateHooks/useRSSFeedIdByUrl';
import { addFeed, removeFeed, toggleDocumentFeedSubscription, updateFeedDescription, updateFeedTitle } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { useMatchingRSS } from '../../../../shared/foreground/useMatchingRss';
import useGlobalStateWithFallback from '../../../../shared/foreground/utils/useGlobalStateWithFallback';
import type { DocumentId, PersistentState, RssFeed, RSSSuggestion } from '../../../../shared/types';
import { MainTitleType } from '../../../../shared/types';
import { isValidRssUrl } from '../../../../shared/utils/isValidRssUrl';
import useThrottle from '../../../../shared/utils/useThrottle';
import { isFeedsSourcesList } from '../../utils/pathnameHelpers';
import useLocation from '../../utils/useLocation';
import FeedFallbackFavicon from '../icons/FeedFallbackFavicon';
import FeedIcon from '../icons/FeedIcon';
import MinusIcon from '../icons/MinusIcon';
import PlusInCircleIcon from '../icons/PlusInCircleIcon';
import { ImageWithFallback } from '../ImageWithFallback';
import { PaletteAction, PaletteGroup } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';
import feedStyles from './FeedsPalette.module.css';

function getAllIndexes(sentence: string, substring: string) {
  // returns all indexes at which `substring` appears in `sentence`
  const indexes = [];
  let end = 0;
  if (!substring) {
    return [];
  }
  for (let i = 0; i < sentence.length; i++) {
    if (sentence.slice(end, end + substring.length).toLowerCase() === substring.toLowerCase()) {
      indexes.push(end);
      end = i + substring.length;
    } else {
      end = i;
    }
  }
  return indexes;
}

function showQueryInResult(sentence: string, query: string) {
  // wraps each `query` substring in `sentence` with <em> tag, returns an Array of elements
  const matchindexes = getAllIndexes(sentence, query);
  if (!matchindexes) {
    return [sentence];
  }
  let currentEnd = 0;
  const parts = [];
  for (const ind of matchindexes) {
    parts.push(sentence.slice(currentEnd, ind));
    parts.push(<em
      className={feedStyles.feedQueryPart}
      key={`${ind}-query`}>{sentence.slice(ind, ind + query.length)}</em>);
    currentEnd = ind + query.length;
  }
  parts.push(sentence.slice(currentEnd));
  return parts;
}

const AddNewFeedAction = ({
                            focused,
                            rssFeeds,
                            fillSuggestions,
                          }: PaletteAction & { rssFeeds: PersistentState['rssFeeds'] | null; fillSuggestions: (s: RSSSuggestion[], q: string) => void; }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { input } = useContext(CmdInputContext);

  const loadNewSuggestions = useThrottle(
    async (input: string) => {
      const response = await background.getRssSuggestions(input);
      fillSuggestions(response, input);
    }, 500, { extraDependencies: [fillSuggestions], trailing: true, leading: true },
  );


  useEffect(() => {
    if (input) {
      loadNewSuggestions(input);
    } else {
      fillSuggestions([], input);
    }
  }, [input, fillSuggestions, loadNewSuggestions]);


  const action = useMemo(() => async () => {
    if (input === '') {
      return;
    }

    if (!isValidRssUrl(input)) {
      createToast({
        content: `Please, use a valid URL`,
        category: 'error',
      });
      return;
    }

    await addFeed({ url: input }, { userInteraction: 'unknown' });
    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });

    if (!isFeedsSourcesList(pathname)) {
      history.push({
        pathname: '/feed',
        search: '?shouldSelectLatestFeedDoc=true',
      });
    }
  }, [input, history, pathname]);

  const childClass = useMemo(() => input ? '' : feedStyles.emptyQuery, [input]);

  if (rssFeeds && input.toLowerCase() in rssFeeds) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} childrenClassName={childClass}>
    <span className={feedStyles.rssFeedItemWrapper}>
      <PlusInCircleIcon className={`${feedStyles.addIcon} ${focused ? feedStyles.focused : ''}`} />
      <span className={feedStyles.feedDisplayItem}>{input}</span>
    </span>
  </PaletteAction>;
};


const AddSuggestedFeedAction = ({
                                  focused,
                                  feedData,
                                  query,
                                }: PaletteAction & { feedData: RSSSuggestion; query: string; }) => {
  const history = useHistory();
  const action = useMemo(() => async () => {
    await addFeed(feedData, { userInteraction: 'unknown' });
    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    history.push({
      pathname: '/feed',
      search: '?shouldSelectLatestFeedDoc=true',
    });
  }, [history, feedData]);

  const display = feedData.name && feedData.description ? <>{...showQueryInResult(feedData.name, query)} <span
    className={feedStyles.feedDisplayItemDescription}>{...showQueryInResult(feedData.description, query)}</span></> : <>{...showQueryInResult(feedData.url, query)}</>;

  return <PaletteAction focused={focused} action={action} childrenClassName={feedStyles.overflowHidden}>
    <span className={feedStyles.rssFeedItemWrapper}>
      <PlusInCircleIcon className={`${feedStyles.addIcon} ${focused ? feedStyles.focused : ''}`} />
      <ImageWithFallback
        className={feedStyles.rssFeedIcon} imageUrl={feedData.image_url}
        fallbackImage={<FeedFallbackFavicon className={feedStyles.rssFeedIcon} />}
      />
      <span className={feedStyles.feedDisplayItem}>{display}</span>
    </span>
  </PaletteAction>;
};

const DeleteFeedAction = React.memo(function DeleteFeedAction(
  {
    focused,
    id,
    feedData,
    query,
  }: PaletteAction & { id: string; feedData: RssFeed; query: string; },
) {
  const action = async () => {
    // eslint-disable-next-line no-alert
    const shouldContinue = confirm(`Warning: ${messageCopy.removeFeedWarningText} Continue?`);

    if (!shouldContinue) {
      return;
    }

    await removeFeed(id, { userInteraction: 'unknown' });
    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
  };

  const display = feedData.name && feedData.description ? <>{...showQueryInResult(feedData.name, query)} <span
    className={feedStyles.feedDisplayItemDescription}>{...showQueryInResult(feedData.description, query)}</span></> : <>{...showQueryInResult(feedData.url, query)}</>;

  return <PaletteAction focused={focused} action={action} childrenClassName={feedStyles.overflowHidden}>
    <span className={feedStyles.rssFeedItemWrapper}>
      <MinusIcon className={`${feedStyles.removeIcon} ${focused ? feedStyles.focused : ''}`} />
      <ImageWithFallback
        className={feedStyles.rssFeedIcon} imageUrl={feedData.image_url}
        fallbackImage={<FeedIcon className={feedStyles.rssFeedIcon} />}
      />
      <span className={feedStyles.feedDisplayItem}>{display}</span>
    </span>
  </PaletteAction>;
});


export const ManageFeedSubscriptionFeedAction = React.memo(function ManageFeedSubscriptionFeedAction({
 focused,
 docId,
 shortcut,
}: PaletteAction & { docId: DocumentId; }) {

  const [existingDoc] = usePartialDocument(docId, ['url', 'category', 'source_specific_data']);
  const { possibleRss, subscribed } = useMatchingRSS(existingDoc);
  const feedId = useRSSFeedIdByUrl(possibleRss?.url);
  const action = useMemo(() => async () => toggleDocumentFeedSubscription(
      possibleRss, subscribed, feedId, messageCopy.removeFeedWarningText, { userInteraction: 'unknown' },
      ), [feedId, possibleRss, subscribed]);
  const commandCopy = useMemo(() => subscribed ? 'Unsubscribe from feed' : 'Subscribe to feed', [subscribed]);

  return possibleRss ? <PaletteAction focused={focused} action={action} shortcut={shortcut}>
      {commandCopy}
    </PaletteAction> : null;
});


export const FeedsPalette = (): JSX.Element => {
  const rssFeeds = useRssFeeds();
  const [suggestions, setSuggestions] = useState<RSSSuggestion[]>([]);
  const [query, setQuery] = useState<string>('');

  const onSuggestionsLoaded = useCallback((rssSuggestions: RSSSuggestion[], searchTerm: string) => {
    const knownFeeds = Object.values(rssFeeds).map((f) => f.name);
    setSuggestions(rssSuggestions.filter((feed) => !knownFeeds.includes(feed.name)));
    setQuery(searchTerm);
  }, [rssFeeds]);

  return (
    <PaletteWrapper title="Add Feeds" placeholder="Start typing the name or URL of your RSS feed">
      <PaletteGroup title="Feeds">
        {suggestions.map((feed, i) => <AddSuggestedFeedAction
          key={`${feed.id}-suggestion`} focused={false}
          feedData={feed} query={query} uniqueId={`${feed.id}-suggestion`} mainTitleType={MainTitleType.Rss} />)}
        <AddNewFeedAction
          key="new-feed" focused={false} rssFeeds={rssFeeds} fillSuggestions={onSuggestionsLoaded}
          mainTitleType={MainTitleType.Rss} />
        {Object.keys(rssFeeds).map((id) => {
          const feed = rssFeeds[id];
          const label = `${feed.name} ${feed.url} ${feed.description}`;
          return <DeleteFeedAction
            key={id} focused={false} id={id} feedData={rssFeeds[id]} label={label} uniqueId={`${id}-delete`}
            mainTitleType={MainTitleType.Rss} query={query} />;
        })}
      </PaletteGroup>
    </PaletteWrapper>
  );
};

const EditFeedNameAction = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const rssFeeds = useGlobalStateWithFallback({}, useCallback((state) => state.persistent.rssFeeds, []));
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId || '', []));
  const feedName = rssFeeds[focusedFeedId]?.name;

  useEffect(() => {
    setInput(feedName || '');
  }, [setInput, feedName]);

  const action = useMemo(() => async () => {
    if (input === '') {
      return;
    }

    updateFeedTitle(focusedFeedId, input, { userInteraction: 'enter' });
    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
  }, [input, focusedFeedId]);

  return <PaletteAction focused={focused} action={action}>Save changes</PaletteAction>;
};

const EditFeedDescriptionAction = ({ focused }: PaletteAction) => {
  const { input, setInput } = useContext(CmdInputContext);
  const rssFeeds = useGlobalStateWithFallback({}, useCallback((state) => state.persistent.rssFeeds, []));
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId || '', []));
  const feedDescription = rssFeeds[focusedFeedId]?.description || '';

  useEffect(() => {
    setInput(feedDescription || '');
  }, [setInput, feedDescription]);

  const action = useMemo(() => async () => {
    if (input === '') {
      return;
    }

    updateFeedDescription(focusedFeedId, input, { userInteraction: 'enter' });
    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
  }, [input, focusedFeedId]);

  return <PaletteAction focused={focused} action={action}>Save changes</PaletteAction>;
};

const OpenEditFeedNameAction = ({ focused, setCurrentAction }: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(() => async () => {
    setCurrentAction('edit-feed-name');
  }, [setCurrentAction]);

  return <PaletteAction focused={focused} action={action}>Edit feed name</PaletteAction>;
};

const OpenEditFeedDescriptionAction = ({ focused, setCurrentAction }: PaletteAction & { setCurrentAction: (action: string) => void; }) => {
  const action = useMemo(() => async () => {
    setCurrentAction('edit-feed-description');
  }, [setCurrentAction]);

  return <PaletteAction focused={focused} action={action}>Edit feed description</PaletteAction>;
};

export const EditFeedPalette = (): JSX.Element => {
  const [currentAction, setCurrentAction] = useState<string | null>(null);

  return (
    <PaletteWrapper title="Edit Feed" placeholder="Type a command">
      <PaletteGroup title="">
        {!currentAction && <OpenEditFeedNameAction label="Edit feed name" focused={false} setCurrentAction={setCurrentAction} mainTitleType={MainTitleType.EditFeed} />}
        {!currentAction && <OpenEditFeedDescriptionAction label="Edit feed description" focused={false} setCurrentAction={setCurrentAction} mainTitleType={MainTitleType.EditFeed} />}
        {currentAction === 'edit-feed-name' && <EditFeedNameAction focused={false} mainTitleType={MainTitleType.EditFeed} />}
        {currentAction === 'edit-feed-description' && <EditFeedDescriptionAction focused={false} mainTitleType={MainTitleType.EditFeed} />}
      </PaletteGroup>
    </PaletteWrapper>
  );
};
