import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { updateDocumentLocation } from '../../../../shared/foreground/actions/documentLocations';
import clearAllLocalData from '../../../../shared/foreground/clearAllLocalData';
import {
  openAccountIssueSubMenu,
  openBugReportSubMenu,
  openBulkActionsSubMenu,
  openFeatureRequestSubMenu,
  openFeedsSubMenu,
  openFiltersSubMenu,
  openProductQuestionSubMenu,
  openReferFriendSubMenu,
  openReportingSubMenu,
  openSaveDocSubMenu,
  openSaveFilterSubMenu,
  openSplitBySubMenu,
  openViewFiltersSubMenu,
  setCmdPaletteOpen,
} from '../../../../shared/foreground/cmdPalette';
import copyDocumentUrlOnWeb from '../../../../shared/foreground/copyDocumentUrlOnWeb';
import { setDocumentsSortMenuOpen } from '../../../../shared/foreground/documentsSortMenu';
import eventEmitter from '../../../../shared/foreground/eventEmitter';
import {
  FontSizeNumberDesktop,
  ForegroundEventName,
  getNextTshirtSizeFromKeys,
  globalState,
  LineHeightNumberDesktop,
  LineLengthNumberDesktop,
  useIsStaffProfile,
} from '../../../../shared/foreground/models';
import background from '../../../../shared/foreground/portalGates/toBackground/singleProcess';
import { useIsPDFViewAsHTML, usePartialDocument } from '../../../../shared/foreground/stateHooks';
import useFocusedDocument, { useFocusedDocumentId } from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import {
  toggleAutoAdvance,
  toggleDarkModeTheme,
  toggleHideLeftPanelOnEnteringReadingView,
  toggleHidePanelsOnEnteringReadingView,
  toggleHideRightPanelOnEnteringReadingView,
} from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/other';
import {
  cycleTypeface,
  setFontSize,
  setLineSpacing,
  setReaderHorizontalMargin,
  setTextDirection,
  toggleShouldInvertPDFColors,
} from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import {
  bumpDocumentForUser,
  moveRandomDocsToInbox,
  safeDelete,
  toggleDocumentOpened,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { saveNewDocument } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/create';
import {
  toggleEpubOriginalStyles,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/epub';
import { removeTag } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import { updateDocument } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/update';
import { updateFilteredView } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { queueJob } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/jobs';
import { resetOnboardingState } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/onboarding';
import { updateDefaultPageSetting } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/settings';
import {
  setFocusedDocumentId,
  toggleDocumentMetadataOpen,
  toggleZenMode,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import {
  toggleHideLeftSidebar,
  toggleHideRightSidebar,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import useCurrentFilteredView from '../../../../shared/foreground/useCurrentFilteredView';
import { useMatchingRSS } from '../../../../shared/foreground/useMatchingRss';
import { markFeedAsPartial } from '../../../../shared/foreground/utils/CreateLinearIssue';
import exportDocumentsToFile from '../../../../shared/foreground/utils/exportDocumentsToFile';
import exportFull from '../../../../shared/foreground/utils/exportFull';
import exportOPMLToFile from '../../../../shared/foreground/utils/exportOPMLToFile';
import exportUploadedFiles from '../../../../shared/foreground/utils/exportUploadedFiles';
import getUIFriendlyNameForDocumentLocation
  from '../../../../shared/foreground/utils/getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from '../../../../shared/foreground/utils/useDocumentLocations';
import {
  Category,
  DefaultPage,
  DisplayTheme,
  DocumentId,
  DocumentLocation,
  JobType,
  MainTitleType,
  NotebookKind,
  ShortcutId,
  TextDirection,
  TshirtSize,
} from '../../../../shared/types';
import { isDocumentWithPublishedDate, isDocumentWithTTS } from '../../../../shared/typeValidators';
import formatPublishedDate from '../../../../shared/utils/dates/formatPublishedDate';
import { isDevOrTest } from '../../../../shared/utils/environment';
import getDocumentAuthor from '../../../../shared/utils/getDocumentAuthor';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import { isValidHttpUrl } from '../../../../shared/utils/isValidHttpUrl';
import { printDocumentContent } from '../../../../shared/utils/printDocumentContent';
import urlJoin from '../../../../shared/utils/urlJoin';
import { useIsLeftSidebarHidden, useIsRightSidebarHidden, useNotebookViewParams } from '../../hooks/hooks';
import focusDocumentNoteField from '../../utils/focusDocumentNoteField';
import { openOriginalDocument } from '../../utils/openOriginalDocument';
import { openURL } from '../../utils/openURL';
import {
  getCurrentDocumentLocationFromPathname,
  getFilterViewQueryFromPathname,
  isDocumentPathname,
  isFeedView,
  isFiltersView,
  isLibraryView,
  isSearchView,
  shouldShowBulkActionsMenu,
} from '../../utils/pathnameHelpers';
import { documentLocationShortcutIdMap, useShortcutsMap } from '../../utils/shortcuts';
import useLocation from '../../utils/useLocation';
import { FileUploadContext } from '../FileDropzone';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import NavigationPaletteAction from './Base/NavigationPaletteAction';
import { PaletteAction, PaletteGroup } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';
import ShortcutPaletteAction from './Base/ShortcutPaletteAction';
import { ManageFeedSubscriptionFeedAction } from './FeedsPalette';
import { GoToViewsPageAction } from './FiltersPalette';
import {
  TtsJumpAction,
  TtsPlayrateAction,
  TtsResumeOrPauseAction,
  TtsStartAction,
  TtsStopAction,
  TtsVolumeAction,
} from './TtsPaletteActions';

const FontSizesKeys = Object.keys(FontSizeNumberDesktop);
const LineHeightSizesKeys = Object.keys(LineHeightNumberDesktop);
const LineLengthKeys = Object.keys(LineLengthNumberDesktop);

const AddFeedMenuAction = React.memo(function AddFeedMenuAction({ focused, shortcut }: PaletteAction) {
  return <PaletteAction
    focused={focused} action={openFeedsSubMenu}
    shortcut={shortcut}>Add/remove RSS
    subscriptions</PaletteAction>;
});

const ViewFeedSourceAction = React.memo(function ViewFeedSourceAction({ focused }: PaletteAction) {
  const history = useHistory();

  const viewFeedSources = useMemo(() => async () => {
    history.push({
      pathname: '/feed/sources',
    });
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [history]);

  return <PaletteAction focused={focused} action={viewFeedSources}>Manage feed sources</PaletteAction>;
});

const OPMLExportAction = React.memo(function OPMLExportAction({ focused }: PaletteAction) {
  const action = async () => {
    await exportOPMLToFile();
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  };
  return <PaletteAction focused={focused} action={action}>Generate OPML export (of all RSS feeds)</PaletteAction>;
});

const FilterAllDocumentsAction = React.memo(function FilterAllDocumentsAction({ focused, shortcut }: PaletteAction) {
  return <PaletteAction
    focused={focused} action={openFiltersSubMenu}
    shortcut={shortcut}>Filter all documents</PaletteAction>;
});

const SaveFilterAction = React.memo(function SaveFilterAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} action={openSaveFilterSubMenu}>Save current filtered view</PaletteAction>;
});

const ViewFiltersAction = React.memo(function ViewFiltersAction({ focused, shortcut }: PaletteAction) {
  return <PaletteAction
    focused={focused} action={openViewFiltersSubMenu}
    shortcut={shortcut}>Open quick view switcher</PaletteAction>;
});

const SplitByAction = React.memo(function SplitByAction({ focused, shortcut }: PaletteAction) {
  return <PaletteAction
    focused={focused} action={openSplitBySubMenu}
    shortcut={shortcut}>Split by</PaletteAction>;
});

const ToggleBadgeCountAction = React.memo(function ToggleBadgeCountAction({ focused }: PaletteAction) {
  const location = useLocation();
  const pathName = location.pathname;
  const currentQuery = useMemo(() => getFilterViewQueryFromPathname(pathName) || '', [pathName]);
  const currentView = useCurrentFilteredView(currentQuery);
  const actionName = currentView?.showCountBadge ? 'Hide count badge' : 'Show count badge';

  const action = useCallback(() => {
    if (!currentView) {
      return;
    }

    updateFilteredView({
      ...currentView,
      showCountBadge: !currentView.showCountBadge,
    }, { userInteraction: 'keypress' });
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [currentView]);

  if (!currentView) {
    return null;
  }

  return <PaletteAction
    focused={focused} action={action}>{actionName}</PaletteAction>;
});

const EditTagsMenuAction = React.memo(function EditTagsMenuAction({
  docId,
  focused,
  insideFeed,
  insideFilters,
  insideInbox,
  insideSearch,
}: PaletteAction & {
  docId: DocumentId | null;
  insideFeed: boolean;
  insideFilters: boolean;
  insideInbox: boolean;
  insideSearch: boolean;
}) {
  const shortcutsMap = useShortcutsMap();
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const isReadingView = pathname.includes('/read/');
  const shortcut = isReadingView ? shortcutsMap[ShortcutId.DocumentReaderTag] : shortcutsMap[ShortcutId.Tags];

  const action = () => {
    if (insideFeed || insideFilters || insideInbox || insideSearch) {
      eventEmitter.emit('open-document-list-edit-tags-popover', docId);
    } else {
      eventEmitter.emit('open-document-header-edit-tags-popover');
    }
    setCmdPaletteOpen(false, { userInteraction: null });
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcut}>Add/remove document tags</PaletteAction>;
});

const EditDocNoteAction = React.memo(function EditDocNoteAction({ focused, shortcut }: PaletteAction) {
  const action = () => {
    focusDocumentNoteField();
    setCmdPaletteOpen(false, { userInteraction: null });
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcut}>Add/remove document note</PaletteAction>;
});

const MoveDocAction = React.memo(function MoveDocAction({
  docId,
  documentLocation,
  focused,
}: PaletteAction & { docId: DocumentId | null; documentLocation: DocumentLocation; }) {
  const shortcutsMap = useShortcutsMap();
  const shortcutId = documentLocationShortcutIdMap[documentLocation];
  const shortcut = shortcutsMap[ShortcutId[shortcutId]];

  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    updateDocumentLocation(docId, documentLocation, { userInteraction: 'unknown' });
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [docId, documentLocation]);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Move
    to {getUIFriendlyNameForDocumentLocation(documentLocation)}</PaletteAction>;
});

const DeleteSpecificTagAction = React.memo(function DeleteSpecificTagAction({
  focused,
  docId,
  tagName,
}: PaletteAction & { docId: DocumentId | null; tagName: string; }) {
  const action = async () => {
    if (docId) {
      await removeTag(docId, tagName, { userInteraction: 'unknown' });
    }
  };
  return <PaletteAction focused={focused} action={action}>Remove tag: {tagName}</PaletteAction>;
});

const ClearCacheAction = React.memo(function ClearCacheAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} action={clearAllLocalData}>Developer: clear all local data (cache)</PaletteAction>;
});

const FullPocketSync = React.memo(function FullPocketSync({ focused }: PaletteAction) {
  const action = useMemo(() => async () => {
    await queueJob({
      jobType: JobType.FullPocketImport,
      jobArguments: {},
      options: { userInteraction: 'click' },
    });
    background.pollLatestStateAndContent(20);
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);

  return <PaletteAction focused={focused} action={action}>Import entire Pocket library</PaletteAction>;
});

const DownloadDebuggingData = React.memo(function DownloadDebuggingData({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} action={background.downloadSyncerCacheForDebugging}>Developer: download
    debugging data</PaletteAction>;
});

export const ProductQuestionAction = React.memo(function ReportParsingFailAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} icon={<ChevronRightIcon />} action={openProductQuestionSubMenu}>Product question</PaletteAction>;
});

export const AccountIssueAction = React.memo(function ReportParsingFailAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} icon={<ChevronRightIcon />} action={openAccountIssueSubMenu}>Account/billing issue</PaletteAction>;
});

export const ReportParsingFailAction = React.memo(function ReportParsingFailAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} icon={<ChevronRightIcon />} action={openReportingSubMenu}>Parsing issue</PaletteAction>;
});

export const FeatureRequestAction = React.memo(function FeatureRequestAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} icon={<ChevronRightIcon />} action={openFeatureRequestSubMenu}>Feature suggestion</PaletteAction>;
});

export const BugReportAction = React.memo(function BugReportAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} icon={<ChevronRightIcon />} action={openBugReportSubMenu}>Report bug</PaletteAction>;
});

const ReportPartialFeedAction = React.memo(function ReportPartialFeedAction({ focused }: PaletteAction) {
  const docId = useFocusedDocumentId();
  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    await markFeedAsPartial(docId);
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [docId]);
  return <PaletteAction focused={focused} action={action}>Report partial feed content</PaletteAction>;
});

const ToggleDarkModeAction = React.memo(function ToggleDarkModeAction({ focused, shortcut }: PaletteAction) {
  const theme = globalState(useCallback((state) => state.client.theme, []));
  const text = `Toggle theme: ${theme === DisplayTheme.Dark ? 'Light' : 'Dark'} mode`;
  return <PaletteAction
    focused={focused} action={toggleDarkModeTheme}
    shortcut={shortcut}>{text}</PaletteAction>;
});

const ToggleAutoAdvanceAction = React.memo(function ToggleAutoAdvanceAction({ focused }: PaletteAction) {
  const autoAdvance = globalState(useCallback((state) => state.client.autoAdvance, []));
  const text = `Toggle auto-advance: ${autoAdvance ? 'Off' : 'On'}`;

  const action = useMemo(() => async () => {
    toggleAutoAdvance({ userInteraction: 'click', showToast: true });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const ToggleShouldInvertPDFColors = React.memo(function ToggleShouldInvertPDFColors({ focused }: PaletteAction) {
  const text = 'Disable/Enable PDF color inverting';

  const action = useMemo(() => async () => {
    toggleShouldInvertPDFColors();
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const ChangeTextDirectionAction = React.memo(function ChangeTextDirectionAction({ focused }: PaletteAction) {
  const currentDirection = globalState(useCallback((state) => state.client.readerSettings.desktop.direction, []));
  const newTextDirection = currentDirection === TextDirection.LeftToRight ? TextDirection.RightToLeft : TextDirection.LeftToRight;
  const text = `Change text direction to ${newTextDirection.toLocaleUpperCase()}`;

  const action = useMemo(() => async () => {
    setTextDirection(newTextDirection);
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [newTextDirection]);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const UpdateDefaultPageSettingAction = React.memo(function UpdateDefaultPageSettingAction({ focused }: PaletteAction) {
  const defaultPage = globalState(useCallback((state) => state.persistent.settings.defaultPage, []));
  const text = `Open app on the Library: ${defaultPage === 'home' ? 'Off' : 'On'}`;

  const action = useMemo(() => async () => {
    updateDefaultPageSetting(defaultPage === DefaultPage.Home ? DefaultPage.Library : DefaultPage.Home, { userInteraction: 'click', showToast: true });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [defaultPage]);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const ResetOnboardingStateAction = React.memo(function ResetOnboardingStateAction({ focused }: PaletteAction) {
  return <PaletteAction
    focused={focused}
    action={resetOnboardingState}
  >Reset onboarding state
  </PaletteAction>;
});

const ToggleHideLeftSidePanel = React.memo(function ToggleHideLeftSidePanel({ focused, shortcut }: PaletteAction) {
  const isHidden = useIsLeftSidebarHidden();
  const text = isHidden ? 'Show left panel' : 'Hide left panel';
  const action = useMemo(() => async () => {
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    await toggleHideLeftSidebar({ userInteraction: 'unknown' });
  }, []);
  return <PaletteAction
    focused={focused} action={action}
    shortcut={shortcut}>{text}</PaletteAction>;
});

const ToggleHideRightSidePanel = React.memo(function ToggleHideRightSidePanel({ focused, shortcut }: PaletteAction) {
  const isHidden = useIsRightSidebarHidden();
  const text = isHidden ? 'Show right panel' : 'Hide right panel';
  const action = useMemo(() => async () => {
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    await toggleHideRightSidebar({ userInteraction: 'unknown' });
  }, []);
  return <PaletteAction
    focused={focused} action={action}
    shortcut={shortcut}>{text}</PaletteAction>;
});

const ToggleZenModeAction = React.memo(function ToggleZenModeAction({ focused, shortcut }: PaletteAction) {
  const zenMode = globalState(useCallback((state) => state.zenModeEnabled, []));
  const text = zenMode ? 'Disable focus mode' : 'Enable focus mode';
  const action = useMemo(() => async () => {
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    await toggleZenMode({ userInteraction: 'unknown' });
  }, []);
  return <PaletteAction
    focused={focused} action={action}
    shortcut={shortcut}>{text}</PaletteAction>;
});

const FullscreenModeAction = React.memo(function FullscreenModeAction({ focused, shortcut }: PaletteAction) {
  const action = useMemo(() => async () => {
    document.documentElement.requestFullscreen();
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Enter
    fullscreen</PaletteAction>;
});

const CycleTypefaceAction = React.memo(function CycleTypefaceAction({ focused, shortcut }: PaletteAction) {
  const action = useMemo(() => async () => {
    cycleTypeface({ userInteraction: 'unknown' });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Cycle
    typeface</PaletteAction>;
});

const IncreaseFontSizeAction = React.memo(function IncreaseFontSizeAction({ focused, shortcut }: PaletteAction) {
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.fontSize, []));

  const action = useMemo(() => async () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: FontSizesKeys, currentSize });
    setFontSize(nextSize, { userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'click' });
  }, [currentSize]);

  if (currentSize === TshirtSize.XL) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Increase font
    size</PaletteAction>;
});

const DecreaseFontSizeAction = React.memo(function DecreaseFontSizeAction({ focused, shortcut }: PaletteAction) {
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.fontSize, []));

  const action = useMemo(() => async () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: FontSizesKeys, currentSize, direction: -1 });
    setFontSize(nextSize, { userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'click' });
  }, [currentSize]);

  if (currentSize === TshirtSize.XS) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Decrease font
    size</PaletteAction>;
});

const WidenLineLengthAction = React.memo(function WidenLineLengthAction({ focused, shortcut }: PaletteAction) {
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.lineLength, []));

  const action = useMemo(() => async () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: LineLengthKeys, currentSize });
    setReaderHorizontalMargin(nextSize, { userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'click' });
  }, [currentSize]);

  if (currentSize === TshirtSize.XL) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Widen line
    length</PaletteAction>;
});

const NarrowLineLengthAction = React.memo(function NarrowLineLengthAction({ focused, shortcut }: PaletteAction) {
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.lineLength, []));

  const action = useMemo(() => async () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: LineLengthKeys, currentSize, direction: -1 });
    setReaderHorizontalMargin(nextSize, { userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'click' });
  }, [currentSize]);

  if (currentSize === TshirtSize.XS) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Narrow line
    length</PaletteAction>;
});

const IncreaseLineSpacingAction = React.memo(function IncreaseLineSpacingAction({ focused, shortcut }: PaletteAction) {
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.lineHeight, []));

  const action = useMemo(() => async () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: LineHeightSizesKeys, currentSize });
    setLineSpacing(nextSize, { userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'click' });
  }, [currentSize]);

  if (currentSize === TshirtSize.XL) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Increase
    line spacing</PaletteAction>;
});

const DecreaseLineSpacingAction = React.memo(function DecreaseLineSpacingAction({ focused, shortcut }: PaletteAction) {
  const currentSize = globalState(useCallback((state) => state.client.readerSettings.desktop.lineHeight, []));

  const action = useMemo(() => async () => {
    const nextSize = getNextTshirtSizeFromKeys({ keys: LineHeightSizesKeys, currentSize, direction: -1 });
    setLineSpacing(nextSize, { userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'click' });
  }, [currentSize]);

  if (currentSize === TshirtSize.XS) {
    return null;
  }

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Decrease
    line spacing</PaletteAction>;
});


const SortDocumentListAction = React.memo(function SortDocumentListAction({ focused }: PaletteAction) {
  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const isReadingView = pathname.includes('/read/');
  const isImportPage = pathname === 'import';
  const isFeedSources = pathname === 'feed/sources';

  if (isReadingView || isImportPage || isFeedSources) {
    return null;
  }

  const action = async () => {
    await setDocumentsSortMenuOpen(true, { userInteraction: 'unknown' });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  };

  return <PaletteAction focused={focused} action={action}>Sort document list</PaletteAction>;
});

const CustomizeShortcutsAction = React.memo(function CustomizeShortcutsAction({ focused }: PaletteAction) {
  const history = useHistory();

  const action = () => {
    history.push('/preferences/shortcuts');
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  };

  return <PaletteAction focused={focused} action={action}>Customize keyboard shortcuts</PaletteAction>;
});

const ExportDocumentsAction = React.memo(function ExportDocumentsAction({ focused }: PaletteAction) {
  const action = async () => {
    createToast({ category: 'default', content: 'Generating CSV of all documents...' });
    exportDocumentsToFile();
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  };
  return <PaletteAction focused={focused} action={action}>Generate CSV export (of all documents)</PaletteAction>;
});

const ExportFilesAction = React.memo(function ExportFilesAction({ focused }: PaletteAction) {
  const action = async () => {
    createToast({ category: 'default', content: 'Generating export of all uploaded files...' });
    exportUploadedFiles();
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  };
  return <PaletteAction focused={focused} action={action}>Generate download (of uploaded EPUBs and PDFs)</PaletteAction>;
});

const ExportFullAction = React.memo(function ExportFullAction({ focused }: PaletteAction) {
  const action = async () => {
    createToast({ category: 'default', content: 'Generating export of all your files...' });
    exportFull();
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  };
  return <PaletteAction focused={focused} action={action}>Export all of your data</PaletteAction>;
});


const BumpDocAction = React.memo(function BumpDocAction({
  focused,
  docId,
  currentDocumentLocation,
  shortcut,
}: PaletteAction & { docId: DocumentId | null; currentDocumentLocation: DocumentLocation; }) {
  const action = useMemo(() => () => {
    if (!docId) {
      return;
    }
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    setFocusedDocumentId(null, { userInteraction: 'unknown' });
    bumpDocumentForUser(docId, { userInteraction: 'unknown' });
  }, [docId]);

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Bump document to top
    of {getUIFriendlyNameForDocumentLocation(currentDocumentLocation)}</PaletteAction>;
});

const ShowOrHideDocMetadataAction = React.memo(function ShowOrHideDocMetadataAction({
  focused,
  docId,
  shortcut,
}: PaletteAction & { docId: DocumentId | null; }) {
  const action = useMemo(() => () => {
    if (!docId) {
      return;
    }
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    toggleDocumentMetadataOpen({ userInteraction: 'unknown' });
  }, [docId]);
  return <PaletteAction
    focused={focused} action={action}
    shortcut={shortcut}>Edit metadata</PaletteAction>;
});

const DeleteDocAction = React.memo(function DeleteDocAction({
  focused,
  docId,
  shortcut,
}: PaletteAction & { docId: DocumentId | null; }) {
  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    await setFocusedDocumentId(null, { userInteraction: 'unknown' });
    await safeDelete(docId, { userInteraction: 'unknown' });
  }, [docId]);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Delete
    document</PaletteAction>;
});

const OpenOriginalArticleAction = React.memo(function OpenOriginalArticleAction({
  focused,
  docId,
  shortcut,
}: PaletteAction & { docId: DocumentId | null; }) {
  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    openOriginalDocument(docId);
  }, [docId]);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Open original
    document</PaletteAction>;
});

const OpenParentDocAction = React.memo(function OpenParentDocAction({
  docId,
  shortcut,
  focused,
}: PaletteAction & { docId: DocumentId | null; }) {
  const history = useHistory();

  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    history.push(urlJoin(['/read', docId]));
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [docId, history]);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Open parent document</PaletteAction>;
});

const ToggleOpenedArticleAction = React.memo(function ToggleOpenedArticleAction({
  focused,
  docId,
  shortcut,
}: PaletteAction & { docId: DocumentId | null; }) {
  const [doc] = useFocusedDocument();

  const text = doc && doc.firstOpenedAt ? 'Mark document as unseen' : 'Mark document as seen';

  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    toggleDocumentOpened(docId, true);
  }, [docId]);
  return <PaletteAction
    focused={focused} action={action}
    shortcut={shortcut}>{text}</PaletteAction>;
});

const CopyDocumentUrlAction = React.memo(function CopyDocumentUrlAction({
  focused,
  docId,
  shortcut,
}: PaletteAction & { docId: DocumentId | null; }) {
  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    copyDocumentUrlOnWeb(docId);
  }, [docId]);
  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>Copy document
    url</PaletteAction>;
});

const DownloadOrPrintPdfAction = React.memo(function DownloadOrPrintPdfAction({
  focused,
  docId,
}: PaletteAction & { docId: DocumentId | null; }) {
  const showHTMLContentForPDF = useIsPDFViewAsHTML(docId);
  const shortcutsMap = useShortcutsMap();
  const printShortcut = shortcutsMap[ShortcutId.PrintDocument];
  const downloadShortcut = shortcutsMap[ShortcutId.DownloadDocument];
  const [doc] = usePartialDocument(docId, ['notes', 'title', 'overrides', 'category', 'author', 'published_date']);
  const docNotes = useMemo(() => doc?.notes, [doc]);
  const docTitle = useMemo(() => getDocumentTitle(doc), [doc]);
  const author = useMemo(() => getDocumentAuthor(doc) || '', [doc]);
  const publishedDate = useMemo(() => doc && isDocumentWithPublishedDate(doc) && doc.published_date
    ? formatPublishedDate(doc.published_date)
    : '', [doc]);
  const shortcut = showHTMLContentForPDF ? printShortcut : downloadShortcut;
  const actionText = showHTMLContentForPDF ? 'Print with annotations' : 'Download with annotations';

  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }

    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

    if (showHTMLContentForPDF) {
      printDocumentContent({ docTitle, docNotes, author, publishedDate });
      return;
    }

    eventEmitter.emit(ForegroundEventName.DownloadPdf);
  }, [docId, showHTMLContentForPDF, docTitle, docNotes, author, publishedDate]);

  return <PaletteAction focused={focused} action={action} shortcut={shortcut}>{actionText}</PaletteAction>;
});

const OpenBulkActionsAction = React.memo(function OpenBulkActionsAction({ focused, shortcut }: PaletteAction) {
  return <PaletteAction
    focused={focused} action={openBulkActionsSubMenu}
    shortcut={shortcut}>Apply bulk actions</PaletteAction>;
});

const MoveRandomDocsToInboxAction = React.memo(function MoveRandomDocsToInboxAction({
  currentDocumentLocation,
  focused,
}: PaletteAction & { currentDocumentLocation: DocumentLocation; }) {
  const action = useMemo(() => async () => {
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    await moveRandomDocsToInbox(currentDocumentLocation, { userInteraction: 'unknown' });
  }, [currentDocumentLocation]);
  return <PaletteAction focused={focused} action={action}>
    Resurface 5 random documents
    from {getUIFriendlyNameForDocumentLocation(currentDocumentLocation)} to {getUIFriendlyNameForDocumentLocation(DocumentLocation.New)}
  </PaletteAction>;
});

const DeleteDocumentChildrenReferences = React.memo(function DeleteDocumentChildrenReferences({
  docId,
  focused,
}: PaletteAction & { docId: DocumentId | null; }) {
  const action = useMemo(() => async () => {
    if (!docId) {
      return;
    }
    await updateDocument(docId, (doc) => {
      if (doc.children) {
        doc.children = [];
      }
    }, { eventName: 'document-children-references-deleted', userInteraction: 'unknown' });
  }, [docId]);
  return <PaletteAction action={action} focused={focused}>Delete children references</PaletteAction>;
});

const ToggleEpubOriginalStyles = React.memo(function ToggleEpubOriginalStyles({
  focused,
  docId,
}: PaletteAction & { docId: DocumentId | null; }) {

  const history = useHistory();
  const pathname = history.location.pathname.substring(1);
  const isReadingView = pathname.includes('/read/');

  const [doc] = usePartialDocument(docId, ['category']);

  if (!isReadingView && !docId) {
    return null;
  }

  if (!doc || doc.category !== Category.EPUB) {
    return null;
  }
  if (!docId) {
    return null;
  }
  const action = () => {
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    toggleEpubOriginalStyles(docId, { userInteraction: 'unknown' });
  };
  return <PaletteAction
    focused={focused} action={action}
  >Toggle original styles</PaletteAction>;
});

const SaveDocFromUrlAction = ({ focused }: PaletteAction) => {
  const history = useHistory();
  const { input } = useContext(CmdInputContext);
  const action = useMemo(() => async () => {
    if (!input) {
      return;
    }

    if (!isValidHttpUrl(input)) {
      createToast({
        content: 'Invalid URL',
        category: 'error',
      });

      return;
    }

    if (input.length > 8000) {
      createToast({
        content: 'URL too long (needs to be below 8000 chars)',
        category: 'error',
      });

      return;
    }


    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    await saveNewDocument({ source: 'Reader add from import URL', url: input }, { userInteraction: 'unknown' });
    background.pollLatestStateAndContent(20);
    history.push('/library');
  }, [input, history]);

  return <PaletteAction focused={focused} action={action}>Save URL</PaletteAction>;
};

export const SaveDocFromUrlPalette = (): JSX.Element => {
  return <PaletteWrapper title="Save URL" placeholder="URL">
    <SaveDocFromUrlAction key="save-url" focused={false} />
  </PaletteWrapper>;
};

const OpenSaveDocFromUrlAction = React.memo(function OpenSaveDocFromUrlAction({ focused, shortcut }: PaletteAction) {
  return <PaletteAction
    focused={focused} action={openSaveDocSubMenu}
    shortcut={shortcut}>Save URL</PaletteAction>;
});

const ToggleHidePanelsInReadingViewByDefaultAction = React.memo(function ToggleHidePanelsInReadingViewByDefaultAction({ focused }: PaletteAction) {
  const hideLeftPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideLeftPanelOnEnteringReadingView, []));
  const hideRightPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideRightPanelOnEnteringReadingView, []));
  const hidePanelsOnEnteringReadingView = useMemo(() => hideLeftPanelOnEnteringReadingView && hideRightPanelOnEnteringReadingView, [hideLeftPanelOnEnteringReadingView, hideRightPanelOnEnteringReadingView]);
  const text = `${hidePanelsOnEnteringReadingView ? 'Show' : 'Hide'} side panels by default in reading view`;

  const action = useMemo(() => async () => {
    toggleHidePanelsOnEnteringReadingView({ userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const ToggleHideRightPanelInReadingViewByDefaultAction = React.memo(function ToggleHideRightPanelInReadingViewByDefaultAction({ focused }: PaletteAction) {
  const hideRightPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideRightPanelOnEnteringReadingView, []));
  const text = `${hideRightPanelOnEnteringReadingView ? 'Show' : 'Hide'} right side panel by default in reading view`;

  const action = useMemo(() => async () => {
    toggleHideRightPanelOnEnteringReadingView({ userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const ToggleHideLeftPanelInReadingViewByDefaultAction = React.memo(function ToggleHideLeftPanelInReadingViewByDefaultAction({ focused }: PaletteAction) {
  const hideLeftPanelOnEnteringReadingView = globalState(useCallback((state) => state.client.hideLeftPanelOnEnteringReadingView, []));
  const text = `${hideLeftPanelOnEnteringReadingView ? 'Show' : 'Hide'} left side panel by default in reading view`;

  const action = useMemo(() => async () => {
    toggleHideLeftPanelOnEnteringReadingView({ userInteraction: 'click' });
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, []);

  return <PaletteAction focused={focused} action={action}>{text}</PaletteAction>;
});

const UploadFileAction = React.memo(function UploadFileAction({ focused, shortcut }: PaletteAction) {
  const { openFileDialog } = useContext(FileUploadContext);

  const action = useMemo(() => async () => {
    openFileDialog();
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  }, [openFileDialog]);

  return <PaletteAction focused={focused} shortcut={shortcut} action={action}>Upload file (OPML,
    CSV, PDF, EPUB)</PaletteAction>;
});

const ReferFriendAction = ({ focused }: PaletteAction) => {
  const { input } = useContext(CmdInputContext);

  const action = useMemo(() => async () => {
    if (!input) {
      return;
    }

    const to = 'hello+reader@readwise.io';
    const subject = encodeURIComponent('👋 I\'d like to refer my friend to the Readwise Reader beta');
    const body = encodeURIComponent('Hey Readwise team, I\'d like to help my friend (copied) get early access to the Reader beta when you get a chance. Thanks! 🙏');
    const cc = input;

    openURL(`mailto:${to}?subject=${subject}&body=${body}&cc=${cc}`, '_self');

    await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
  }, [input]);

  return <PaletteAction focused={focused} action={action}>Send email</PaletteAction>;
};

export const ReferFriendPalette = (): JSX.Element => {
  return <PaletteWrapper title="Refer friend to Reader" placeholder="Enter your friend's email address">
    <ReferFriendAction key="friend-email" focused={false} />
  </PaletteWrapper>;
};

const OpenReferFriendAction = React.memo(function OpenReferFriendAction({ focused }: PaletteAction) {
  return <PaletteAction focused={focused} action={openReferFriendSubMenu}>Refer friend to Reader</PaletteAction>;
});

export const GeneralPalette = (): JSX.Element => {
  const [doc] = useFocusedDocument();

  const location = useLocation();
  const shortcutsMap = useShortcutsMap();
  const pathName = location.pathname;
  const insideInbox = isLibraryView(pathName);
  const insideFeed = isFeedView(pathName);
  const insideFilters = isFiltersView(pathName);
  const insideSearch = isSearchView(pathName);
  const isInsideDocument = isDocumentPathname(pathName);
  const isOnHome = useMemo(() => /\/home\/?$/.test(location.pathname), [location.pathname]);
  const currentDocumentLocation = getCurrentDocumentLocationFromPathname(pathName);
  const showBulkActions = shouldShowBulkActionsMenu(pathName);
  const documentLocations = useDocumentLocations();
  const isReadingView = pathName.includes('/read/');
  const notebookViewParams = useNotebookViewParams();
  const showMoveRandomDocsToInboxAction = (insideInbox || insideFeed) &&
    documentLocations.includes(DocumentLocation.New) &&
    currentDocumentLocation && currentDocumentLocation !== DocumentLocation.New;
  const tags = Object.values(doc?.tags ?? {});
  const isStaff = useIsStaffProfile();
  const isDistributable = Boolean(doc && !doc.non_distributable);
  let docCategory = '';

  const { possibleRss } = useMatchingRSS(doc);
  const docIsEmailOrRSS = doc?.category === Category.Email || possibleRss;
  const emailDocHasNoFromEmail = doc?.category === Category.Email && !doc?.source_specific_data?.email?.from_email;

  const moveDocActions: React.ReactNode[] = useMemo(() => [], []);
  if (doc) {
    const docDocumentLocation = doc.triage_status;
    docCategory = doc.category;
    moveDocActions.length = 0;
    moveDocActions.push(...documentLocations
      .filter((documentLocationItem) => documentLocationItem !== docDocumentLocation)
      .map((documentLocation) => {
        const lowercasedKey = documentLocation.toLowerCase() as DocumentLocation;
        /* eslint-disable react/jsx-indent-props */
        return <MoveDocAction
          docId={doc.id}
          documentLocation={documentLocation}
          focused={false}
          key={`move-doc-${lowercasedKey}`}
          label={`move doc ${lowercasedKey}`}
          mainTitleType={MainTitleType.FocusedArticle}
          tags={['move', lowercasedKey, 'triage', getUIFriendlyNameForDocumentLocation(lowercasedKey, false)]}
        />;
        /* eslint-enable react/jsx-indent-props */
      }));
  }

  let ttsPaletteGroup: JSX.Element | null = null;
  const tts = globalState((state) => state.tts);
  const isTtsActive = useMemo(() => Boolean(tts), [tts]);
  const playingDocId = useMemo(() => tts?.playingDocId, [tts?.playingDocId]);
  const [focusedDocument] = useFocusedDocument();
  const isDocumentOpenInPdfView = !useIsPDFViewAsHTML(focusedDocument?.id) && isInsideDocument;

  if (isStaff) {
    const commonTtsActionTags = ['audio', 'listen', 'media', 'sound', 'speech', 'text', 'to', 'tts', 'voice'];
    const canStartTtsForFocusedDocument = focusedDocument &&
      isDocumentWithTTS(focusedDocument, isDocumentOpenInPdfView) &&
      focusedDocument.id !== playingDocId;

    ttsPaletteGroup = <PaletteGroup title="Text-to-speech">
      {isTtsActive && <TtsResumeOrPauseAction
        focused={false}
        label="Pause / resume"
        mainTitleType={MainTitleType.Reader}
        tags={[...commonTtsActionTags, 'begin', 'pause', 'play', 'resume', 'start']}
      />}
      {canStartTtsForFocusedDocument && <TtsStartAction
        doc={focusedDocument}
        focused={false}
        label="Start"
        mainTitleType={MainTitleType.Reader}
        tags={[...commonTtsActionTags, 'begin', 'play']}
      />}
      {isTtsActive && <>
        <TtsStopAction
          focused={false}
          label="Stop and hide player"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'cancel', 'exit']}
        />
        <TtsJumpAction
          direction="forward"
          focused={false}
          label="Jump forwards"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'seek', 'skip']}
        />
        <TtsJumpAction
          direction="backward"
          focused={false}
          label="Jump backwards"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'seek', 'skip']}
        />
        <TtsPlayrateAction
          actionName="increase"
          focused={false}
          label="Increase playback rate"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'fast', 'playrate', 'speed', 'up']}
        />
        <TtsPlayrateAction
          actionName="decrease"
          focused={false}
          label="Decrease playback rate"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'down', 'playrate', 'slow', 'speed']}
        />
        <TtsVolumeAction
          actionName="increase"
          focused={false}
          label="Increase volume"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'loud', 'up']}
        />
        <TtsVolumeAction
          actionName="decrease"
          focused={false}
          label="Decrease volume"
          mainTitleType={MainTitleType.Reader}
          tags={[...commonTtsActionTags, 'down', 'loud', 'quiet']}
        />
      </>}
    </PaletteGroup>;
  }

  const developerDocActions: React.ReactNode[] = [];
  if (isDevOrTest) {
    developerDocActions.length = 0;
    if (doc?.id) {
      developerDocActions.push(
        <DeleteDocumentChildrenReferences
          docId={doc.id} focused={false} key="del-children"
          label="developer: delete children references" tags={['highlight', 'note']}
          mainTitleType={MainTitleType.FocusedArticle} />,
      );
    }
  }

  return <PaletteWrapper title="Type a command">
    <PaletteGroup title="Document Annotations">
      {doc?.id && tags.map((t) => <DeleteSpecificTagAction
        focused={false} docId={doc.id} key={t.name} tagName={t.name}
        label={`remove tag ${t.name}`} tags={[t.name, 'remove', 'tag']}
        mainTitleType={MainTitleType.FocusedArticle} />)}
      {doc?.id && <EditTagsMenuAction
        docId={doc.id}
        focused={false}
        insideFeed={insideFeed}
        insideFilters={insideFilters}
        insideInbox={insideInbox}
        insideSearch={insideSearch}
        label="edit tags"
        mainTitleType={MainTitleType.FocusedArticle}
        tags={['label', 'add', 'edit', 'remove', 'delete']}
      />}
      {doc?.id && isInsideDocument && <ShortcutPaletteAction
        focused={false}
        isOnHome={isOnHome}
        label="Reset reading progress"
        tags={['reset', 'reading', 'progress']}
        uniqueId={ShortcutId.ResetReadingProgress}
        mainTitleType={MainTitleType.FocusedArticle}
        unstyledShortcut={shortcutsMap[ShortcutId.ResetReadingProgress]}
      />}
      {doc?.id && isInsideDocument && <ShortcutPaletteAction
        focused={false}
        isOnHome={isOnHome}
        label="Enable / view public link"
        tags={['enable', 'view', 'public', 'link']}
        uniqueId={ShortcutId.EnableOrViewPublicLink}
        mainTitleType={MainTitleType.FocusedArticle}
        unstyledShortcut={shortcutsMap[ShortcutId.EnableOrViewPublicLink]}
      />}
      {doc?.id && <EditDocNoteAction
        focused={false}
        label="edit doc note"
        mainTitleType={MainTitleType.FocusedArticle}
        tags={['add', 'edit', 'remove', 'delete', 'note', 'notes', 'document', 'doc']}
        shortcut={shortcutsMap[ShortcutId.AddDocNote]}
      />}
      {isInsideDocument && <ShortcutPaletteAction
        focused={false}
        isOnHome={isOnHome}
        label="Toggle auto-highlighting"
        shouldErrorIfKeyboardShorcutNotInState={false}
        uniqueId={ShortcutId.ToggleAutoHighlighting}
        mainTitleType={MainTitleType.Reader}
        unstyledShortcut={shortcutsMap[ShortcutId.ToggleAutoHighlighting]}
      />}
      {doc?.id && <ShortcutPaletteAction
        focused={false}
        isOnHome={isOnHome}
        label="Invoke Ghostreader"
        shouldErrorIfKeyboardShorcutNotInState={false}
        tags={['invoke', 'ghostreader', 'ai']}
        uniqueId={ShortcutId.DocGhostreader}
        mainTitleType={MainTitleType.FocusedArticle}
        unstyledShortcut={shortcutsMap[ShortcutId.DocGhostreader]}
      />}
    </PaletteGroup>
    <PaletteGroup title="Document locations">
      {moveDocActions}
      {showMoveRandomDocsToInboxAction && currentDocumentLocation &&
          <MoveRandomDocsToInboxAction
            currentDocumentLocation={currentDocumentLocation}
            focused={false}
            key={`move-random-docs-${DocumentLocation.New}`}
            label={`resurface 5 random documents from to ${DocumentLocation.New}`}
            tags={['triage', 'resurface']}
            mainTitleType={MainTitleType.Reader} />}
      {doc?.id && currentDocumentLocation &&
          <BumpDocAction
            focused={false}
            docId={doc.id}
            currentDocumentLocation={currentDocumentLocation}
            label="bump document"
            tags={['bump']}
            mainTitleType={MainTitleType.FocusedArticle}
            shortcut={shortcutsMap[ShortcutId.Bump]}
          />
      }
      {doc?.id && <DeleteDocAction
        focused={false} docId={doc.id} label="delete document" tags={['remove']}
        mainTitleType={MainTitleType.FocusedArticle} shortcut={shortcutsMap[ShortcutId.DeleteDocument]} />}
    </PaletteGroup>
    {showBulkActions &&
        <PaletteGroup title="Bulk actions">
          <OpenBulkActionsAction
            focused={false} label="apply bulk actions"
            tags={['modify', 'apply', 'move', 'bulk', 'all', 'modify all', 'move all', 'documents', 'list']}
            shortcut={shortcutsMap[ShortcutId.OpenBulkActionsSubMenu]}
            mainTitleType={MainTitleType.AllDocumentsInList} />
        </PaletteGroup>}
    <PaletteGroup title="Actions">
      {isStaff && <ResetOnboardingStateAction
        focused={false} label="Reset onboarding state"
        mainTitleType={MainTitleType.Reader}
      />
      }
      {notebookViewParams?.notebookKind === NotebookKind.SingleParent && <OpenParentDocAction
        focused={false} docId={notebookViewParams.notebookId} label="open parent document"
        shortcut={shortcutsMap[ShortcutId.ToggleNotebookView]} />}
      {doc?.id && isDistributable && <OpenOriginalArticleAction
        focused={false} docId={doc.id} label="open original article"
        shortcut={shortcutsMap[ShortcutId.OpenOriginalDoc]}
        mainTitleType={MainTitleType.FocusedArticle} />}
      {doc?.id && isDistributable && <CopyDocumentUrlAction
        focused={false} docId={doc.id} label="copy document url"
        shortcut={shortcutsMap[ShortcutId.Share]}
        mainTitleType={MainTitleType.FocusedArticle} />}
      {isReadingView && doc?.id && doc?.category === Category.PDF && <DownloadOrPrintPdfAction
        focused={false} docId={doc.id} label="download or print pdf with annotations"
        mainTitleType={MainTitleType.FocusedArticle} />}
      {doc?.id && <ToggleOpenedArticleAction
        focused={false} docId={doc.id} label="mark seen or unseen"
        shortcut={shortcutsMap[ShortcutId.ToggleDocAsOpened]}
        mainTitleType={MainTitleType.FocusedArticle} />}
      {doc?.id && <ShowOrHideDocMetadataAction
        focused={false} docId={doc.id} label="Edit metadata"
        mainTitleType={MainTitleType.FocusedArticle}
        tags={['edit', 'metadata', 'show', 'hide']} shortcut={shortcutsMap[ShortcutId.ShowDocMetadata]} />}
      {doc?.id && docIsEmailOrRSS && !emailDocHasNoFromEmail && <ShortcutPaletteAction
        focused={false}
        isOnHome={isOnHome}
        label="Subscribe/unsubscribe to document's RSS feed or email address"
        uniqueId={ShortcutId.ToggleRssOrEmailSubscription}
        mainTitleType={MainTitleType.FocusedArticle}
        unstyledShortcut={shortcutsMap[ShortcutId.ToggleRssOrEmailSubscription]}
      />}
      {doc?.id && <ToggleEpubOriginalStyles
        focused={false} docId={doc.id} label="toggle original epub styles"
        mainTitleType={MainTitleType.FocusedArticle} />}
      <FilterAllDocumentsAction
        focused={false} label="filter all documents" tags={['filter']}
        mainTitleType={MainTitleType.FocusedArticle}
        shortcut={shortcutsMap[ShortcutId.OpenFiltersCmdPanel]}
      />
      {insideFilters && <SaveFilterAction
        focused={false} label="save view" tags={['save', 'view', 'filter']}
        mainTitleType={MainTitleType.FocusedArticle} />}
      <ViewFiltersAction
        focused={false} label="browse saved views" tags={['view', 'browse', 'filter']}
        mainTitleType={MainTitleType.FocusedArticle}
        shortcut={shortcutsMap[ShortcutId.OpenFilteredViews]}
      />
      {insideFilters && !isReadingView && <SplitByAction
        focused={false} label="split saved view" tags={['split', 'view', 'filter']}
        mainTitleType={MainTitleType.FocusedArticle}
        shortcut={shortcutsMap[ShortcutId.OpenSplitBySubMenu]}
      />}
      {insideFilters && <ToggleBadgeCountAction
        focused={false} label="Toggle badge count" tags={['badge', 'count', 'filter', 'view']}
        mainTitleType={MainTitleType.FocusedArticle} />}
      <OpenSaveDocFromUrlAction
        focused={false}
        label="Save URL"
        tags={['save', 'import', 'url']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.OpenSaveDocFromUrlPalette]}
      />
      <ShortcutPaletteAction
        focused={false}
        isOnHome={isOnHome}
        label="Search"
        uniqueId={ShortcutId.Search}
        unstyledShortcut={shortcutsMap[ShortcutId.Search]}
        mainTitleType={MainTitleType.Reader}
      />
      <NavigationPaletteAction
        focused={false}
        label="Manage tags"
        pathname="/tags"
        mainTitleType={MainTitleType.Reader}
      />
      {...developerDocActions}
    </PaletteGroup>
    <PaletteGroup title="Filtered Views">
      <GoToViewsPageAction
        focused={false}
        label="Manage saved filtered views"
        tags={['manage', 'saved', 'filtered', 'views']}
        mainTitleType={MainTitleType.Reader}
      />
    </PaletteGroup>
    <PaletteGroup title="Feeds">
      <AddFeedMenuAction
        focused={false} label="add/remove rss feeds" tags={['feed', 'rss', 'unsubscribe']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.ManageFeedSubscriptions]}
      />
      <ViewFeedSourceAction
        focused={false} label="Manage feed sources" tags={['feed', 'rss', 'view']}
        mainTitleType={MainTitleType.Reader} />
      <NavigationPaletteAction
        focused={false}
        label="See suggested RSS feeds"
        pathname="/feed/suggestions"
        mainTitleType={MainTitleType.Reader}
      />
      <OPMLExportAction
        focused={false}
        label="Export feeds opml"
        tags={['feed', 'rss', 'export', 'opml']}
      />
      {doc?.id && <ManageFeedSubscriptionFeedAction
        focused={false} docId={doc.id} label="manage rss feed subscription"
        tags={['feed', 'rss']} mainTitleType={MainTitleType.FocusedArticle}
        shortcut={shortcutsMap[ShortcutId.ToggleRssOrEmailSubscription]}
      />}
    </PaletteGroup>
    <PaletteGroup title="Reading Appearance">
      <ToggleHideLeftSidePanel
        focused={false} label="Toggle hide left side panels" tags={['sidepanel']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.HideLeftPanel]}
      />
      <ToggleHideRightSidePanel
        focused={false} label="Toggle hide right side panels" tags={['sidepanel']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.HideRightPanel]}
      />
      <ToggleZenModeAction
        focused={false} label="Toggle focus mode" tags={['zen', 'focus', 'mode']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.ToggleZenMode]}
      />
      <ToggleDarkModeAction
        focused={false} label="Toggle dark mode" tags={['theme', 'light', 'dark', 'color']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.ToggleDarkMode]}
      />
      <ToggleAutoAdvanceAction
        focused={false} label="Toggle auto advance" tags={['auto', 'advance', 'toggle']}
        mainTitleType={MainTitleType.Reader} />
      <ToggleHidePanelsInReadingViewByDefaultAction
        focused={false} label="Toggle hide panels upon entering a document" tags={['hide', 'show', 'panels', 'toggle']}
        mainTitleType={MainTitleType.Reader} />
      <ToggleHideRightPanelInReadingViewByDefaultAction
        focused={false} label="Toggle hide right panel upon entering a document"
        tags={['hide', 'show', 'panels', 'right', 'toggle']}
        mainTitleType={MainTitleType.Reader} />
      <ToggleHideLeftPanelInReadingViewByDefaultAction
        focused={false} label="Toggle hide left panel upon entering a document"
        tags={['hide', 'show', 'panels', 'left', 'toggle']}
        mainTitleType={MainTitleType.Reader} />
      <UploadFileAction
        focused={false} label="Upload file (OPML, CSV, PDF, EPUB) " tags={['upload', 'import', 'opml', 'csv']}
        shortcut={shortcutsMap[ShortcutId.UploadFile]}
        mainTitleType={MainTitleType.Reader} />
      <ChangeTextDirectionAction
        focused={false} label="Change text direction" tags={['text', 'direction', 'ltr', 'rtl']}
        mainTitleType={MainTitleType.Reader} />
      <ToggleShouldInvertPDFColors
        focused={false} label="Disable/Enable PDF color inverting" tags={['auto', 'pdf', 'invert', 'color', 'dark', 'light']}
        mainTitleType={MainTitleType.Reader} />
      <FullscreenModeAction
        focused={false} label="Toggle full screen" tags={['zen', 'focus', 'fullscreen']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.FullScreen]}
      />
      <UpdateDefaultPageSettingAction
        focused={false} label="Update default page setting" tags={['default', 'page', 'setting', 'library', 'home']}
        mainTitleType={MainTitleType.Reader} />
      <CycleTypefaceAction
        focused={false}
        label="Cycle typeface"
        tags={['font', 'cycle', 'typeface']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.CycleTypeFace]}
      />
      <IncreaseFontSizeAction
        focused={false} label="Increase font size" tags={['font']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.IncreaseFontSize]}
        />
      <DecreaseFontSizeAction
        focused={false} label="Decrease font size" tags={['font']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.DecreaseFontSize]}
      />
      <WidenLineLengthAction
        focused={false} label="Widen line length" tags={['font']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.WidenLineLength]}
      />
      <NarrowLineLengthAction
        focused={false} label="Narrow line length" tags={['font']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.NarrowLineLength]}
      />
      <IncreaseLineSpacingAction
        focused={false} label="Increase line spacing" tags={['font']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.IncreaseLineSpacing]}
      />
      <DecreaseLineSpacingAction
        focused={false} label="Decrease line spacing" tags={['font']}
        mainTitleType={MainTitleType.Reader}
        shortcut={shortcutsMap[ShortcutId.DecreaseLineSpacing]}
      />
    </PaletteGroup>
    <PaletteGroup title="Help">
      <ProductQuestionAction
        focused={false} label="Product question"
        tags={['bug', 'report', 'feedback', 'product', 'question', 'feature', 'improve', 'request']}
        mainTitleType={MainTitleType.Reader} />
      <AccountIssueAction
        focused={false} label="Account/billing issue"
        tags={['bug', 'report', 'feedback', 'account', 'billing', 'question', 'payment']}
        mainTitleType={MainTitleType.Reader} />
      <ReportParsingFailAction
        focused={false} label="feedback report parsing issue"
        tags={['bug', 'report', 'feedback', 'parsing', 'issue', 'provide']}
        mainTitleType={MainTitleType.Reader} />
      <BugReportAction
        focused={false} label="feedback bug report"
        tags={['bug', 'report', 'feedback', 'parsing', 'issue', 'feature', 'request', 'provide']}
        mainTitleType={MainTitleType.Reader} />
      <FeatureRequestAction
        focused={false} label="feedback suggest improvement"
        tags={['bug', 'report', 'feedback', 'parsing', 'issue', 'feature', 'request', 'provide']}
        mainTitleType={MainTitleType.Reader} />
      {docCategory === Category.RSS && <ReportPartialFeedAction
        focused={false} label="report partial feed content"
        tags={['feed', 'report', 'issue', 'parsing', 'provide', 'rss', 'partial', 'bug', 'feature']}
        mainTitleType={MainTitleType.Reader}
      />}
      <OpenReferFriendAction
        focused={false}
        label="Refer friend to Reader"
        tags={['refer', 'friend']}
        mainTitleType={MainTitleType.Reader}
      />
    </PaletteGroup>
    {ttsPaletteGroup}
    <PaletteGroup title="System">
      <SortDocumentListAction
        focused={false} label="Sort document list" tags={['sort', 'order']}
        mainTitleType={MainTitleType.Reader} />
      <ClearCacheAction focused={false} label="developer: clear all local data" tags={['developer', 'clear', 'cache', 'local', 'data']} mainTitleType={MainTitleType.Reader} />
      <DownloadDebuggingData
        focused={false} label="developer: download debugging data"
        mainTitleType={MainTitleType.Reader} />
      <ExportDocumentsAction
        focused={false} label="generate csv export of all documents" tags={['export', 'csv']}
        mainTitleType={MainTitleType.Reader} />
      <ExportFilesAction
        focused={false} label="generate download - of uploaded epubs and pdfs" tags={['export', 'files', 'uploaded']}
        mainTitleType={MainTitleType.Reader} />
      <FullPocketSync focused={false} label="full pocket sync" mainTitleType={MainTitleType.Reader} />
      <ExportFullAction
        focused={false} label="export all of your data" tags={['export', 'files', 'data']}
        mainTitleType={MainTitleType.Reader} />
      <CustomizeShortcutsAction
        focused={false} label="customize keyboard shortcuts" tags={['customize', 'keyboard', 'shortcuts']}
        mainTitleType={MainTitleType.Reader} />
    </PaletteGroup>
  </PaletteWrapper>;
};
