import React from 'react';

import { Dialog } from './Dialog';

export const DeleteAllDocumentsInListDialog = ({ title, isOpen, onConfirm, onCancel }: {title: string; isOpen: boolean; onConfirm: () => void; onCancel: () => void;}) => {
  if (isOpen) {
    return <div>
      <Dialog
        id="delete-all-documents-in-list"
        title={title}
        subtitle="Deleting the documents will also delete their annotations (notes and highlights)."
        actionTitle="Delete"
        cancelTitle="Cancel"
        redActionButton
        action={onConfirm}
        cancelAction={onCancel} />
      </div>;
  }

  return null;
};
