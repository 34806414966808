import React, { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import { openCustomGptSubMenu, openInputGptSubMenu, setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import foregroundEventEmitter from '../../../../shared/foreground/eventEmitter';
import {
  addGptOutputToNote,
  documentPrompts,
  longSelectionPrompts,
  Prompt,
  shortSelectionPrompts,
} from '../../../../shared/foreground/ghostreader';
import {
  focusedHighlightIdState,
  globalState,
  updateState, useIsStaffProfile,
} from '../../../../shared/foreground/models';
import { useDocument } from '../../../../shared/foreground/stateHooks';
import useFocusedDocument from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import {
  saveDocumentNote,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { addTags } from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import {
  resetDocumentSummaryGeneration,
} from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/documentSummary';
import { hideRightSidebar } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/sidebars';
import { createCancelableToast, createToast } from '../../../../shared/foreground/toasts.platform';
import {
  generateArtificialHighlights,
  generateArtificialTags,
  generateSummary,
} from '../../../../shared/foreground/utils/inference';
import {
  Category,
  FirstClassDocument,
  FullZustandState,
  Highlight,
  RightSidebarVisiblePanel,
} from '../../../../shared/types';
import type { DocumentTag } from '../../../../shared/types/tags';
import nowTimestamp from '../../../../shared/utils/dates/nowTimestamp';
import exceptionHandler from '../../../../shared/utils/exceptionHandler.platform';
import getWords from '../../../../shared/utils/getWords';
import pluralize from '../../../../shared/utils/pluralize';
import { getActionKey } from '../../utils/getActionKey';
import { isDocumentPathname } from '../../utils/pathnameHelpers';
import useLocation from '../../utils/useLocation';
import { PaletteAction } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';

const GptPaletteAction = ({ action, focused, children }: PaletteAction & { action: () => void; children: ReactNode; }) => {
  return <PaletteAction
    focused={focused}
    action={action}
  >
    {children}
  </PaletteAction>;
};

const setPrompt = (prompt: string, systemPrompt?: string) => {
  updateState((state) => {
    state.gptPrompt = state.gptPrompt ?? {
      selection: '',
      expandedSelection: '',
      focusIndicatorContents: '',
      systemPrompt: '',
      prompt: '',
    };
    state.gptPrompt.prompt = prompt;
    state.gptPrompt.systemPrompt = systemPrompt;
  }, { eventName: 'gpt-prompt-manual', userInteraction: 'unknown' });
  return prompt;
};

const INPUT_REGEX = /input\("(.+)"\)/;

const promptAction = (prompt: Prompt, gptPrompt: FullZustandState['gptPrompt'], highlight: Highlight | null, document: FirstClassDocument | null) => {
  return () => {
    setPrompt(prompt.prompt, prompt.systemPrompt);

    if (prompt.prompt.match(INPUT_REGEX)) {
      openInputGptSubMenu();
    } else if (prompt.target === 'manual') {
      openCustomGptSubMenu();
    } else {
      addGptOutputToNote(document, gptPrompt, highlight, prompt.prompt, prompt.systemPrompt);
    }
  };
};

// When input("placeholder") is in the prompt, we show this palette
export const InputGptPromptPalette = (): JSX.Element => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));

  const result = useMemo(() => {
    if (!gptPrompt?.prompt) {
      return [null, null];
    }
    return gptPrompt.prompt.match(INPUT_REGEX);
  }, [gptPrompt?.prompt]);

  if (!result?.[0] || !result?.[1]) {
    createToast({
      content: 'No input prompt found',
      category: 'error',
    });
    return <></>;
  }

  const [, placeholder] = result;

  return <PaletteWrapper hasInput title={placeholder}>
    <GptPromptAction highlight={highlight} document={doc} gptPrompt={gptPrompt} />
  </PaletteWrapper>;
};

export const DocumentGptPromptPalette = (): JSX.Element => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc, { isFetching }] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));
  const location = useLocation();
  const pathName = location.pathname;
  const isInsideDocument = isDocumentPathname(pathName);
  const isStaffProfile = useIsStaffProfile();


  useEffect(() => {
    resetDocumentSummaryGeneration('unknown');
  }, [doc]);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (!doc?.transientData?.content?.length) {
      createToast({
        content: 'Cannot invoke Ghostreader on document without a text layer',
        category: 'error',
      });

      setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    }
  }, [doc, isFetching]);

  return (
    <PaletteWrapper title="Choose Prompt">
      {documentPrompts.map((prompt: Prompt) => {
        if (prompt.target === 'summarize-doc-manually') {
          return <GptPaletteAction
            label={prompt.name} key={prompt.name} focused={false} action={async () => {
              if (!doc) {
                return;
              }

              try {
                await setCmdPaletteOpen(false, { userInteraction: 'unknown' });

                const { summary, userEvent } = await generateSummary(doc.id, 'gpt');

                // Legacy behavior for palette-only: prepend the summary result to the document note:
                let newNoteContents = summary;
                if (doc.notes) {
                  newNoteContents += `\n\n${doc.notes}`;
                }
                await saveDocumentNote(doc.id, newNoteContents, { correlationId: userEvent?.correlationId, userInteraction: 'gpt' });
              } catch (_err) {
                createToast({
                  content: 'Failed to generate summary',
                  category: 'error',
                });
              }

              await hideRightSidebar(false, { userInteraction: 'gpt' });
              await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
            }}>
            {prompt.name}
          </GptPaletteAction>;
        }

        if (prompt.target === 'artificial-highlight-doc') {
          if (doc?.category === Category.PDF) {
            return null;
          }

          if (!isInsideDocument) {
            return null;
          }

          return (
            <GptPaletteAction
              label={prompt.name}
              key={prompt.name}
              focused={false}
              action={async () => {
                if (!doc) {
                  return;
                }

                const tags: { [key: string]: DocumentTag; } = {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  '👻 ai highlighted': {
                    name: '👻 ai highlighted',
                    type: 'automatic',
                    created: nowTimestamp(),
                  },
                };

                let toastId;
                try {
                  await setCmdPaletteOpen(false, {
                    userInteraction: 'unknown',
                  });

                  const controller = new AbortController();
                  toastId = createCancelableToast({
                    content: 'Generating highlights',
                    onCancelClick: () => controller.abort(),
                  });

                  const { highlights } = await generateArtificialHighlights(doc.id, controller);
                  foregroundEventEmitter.emit('highlightMatchingTextBlocks', {
                    highlights,
                    tags,
                    userInteraction: 'unknown',
                  });
                  foregroundEventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);

                  if (highlights.length > 0) {
                    createToast({
                      category: 'success',
                      content: `Generated ${highlights.length} ${pluralize('highlight', highlights.length)}`,
                    });
                  } else {
                    createToast({
                      category: 'info',
                      content: 'Couldn\'t find any meaningful highlights',

                    });
                  }
                } catch (error) {
                  if (!(error instanceof DOMException && error.name === 'AbortError')) {
                    createToast({
                      category: 'error',
                      content: 'Failed to generate highlights',
                    });
                    exceptionHandler.captureException(error);
                  }
                } finally {
                  if (toastId) {
                    toast.dismiss(toastId);
                  }
                }
              }}
            >
              {prompt.name}
            </GptPaletteAction>
          );
        }

        if (prompt.target === 'artificial-tag-doc') {
          if (!isStaffProfile) {
            return null;
          }

          return (
            <GptPaletteAction
              label={prompt.name}
              key={prompt.name}
              focused={false}
              action={async () => {
                if (!doc) {
                  return;
                }

                let toastId;
                try {
                  await setCmdPaletteOpen(false, {
                    userInteraction: 'unknown',
                  });

                  const controller = new AbortController();
                  toastId = createCancelableToast({
                    content: 'Extracting tags…',
                    onCancelClick: () => controller.abort(),
                  });

                  const { tags: allTags } = await generateArtificialTags(doc.id, controller);
                  const tags = allTags.slice(0, 6);
                  await addTags(doc.id, tags, {
                    type: 'generated',
                    userInteraction: 'unknown',
                  });

                  if (tags.length > 0) {
                    createToast({
                      category: 'success',
                      content: `Extracted ${tags.length} ${pluralize('tag', tags.length)}`,
                    });
                  } else {
                    createToast({
                      category: 'info',
                      content: 'Couldn\'t find any meaningful tags',

                    });
                  }
                } catch (error) {
                  if (!(error instanceof DOMException && error.name === 'AbortError')) {
                    createToast({
                      category: 'error',
                      content: 'Failed to extract tags',
                    });
                    exceptionHandler.captureException(error);
                  }
                } finally {
                  if (toastId) {
                    toast.dismiss(toastId);
                  }
                }
              }}
            >
              {prompt.name}
            </GptPaletteAction>
          );
        }

        return (
          <GptPaletteAction
            label={prompt.name}
            key={prompt.name}
            focused={false}
            action={promptAction(prompt, gptPrompt, highlight, doc)}
          >
            {prompt.name}
          </GptPaletteAction>
        );
      })}
    </PaletteWrapper>
  );
};

// Palette shown on highlights
export const HighlightGptPromptPalette = (): JSX.Element => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));
  const shortSelection = useMemo(() => gptPrompt?.expandedSelection && getWords(gptPrompt.expandedSelection, doc?.language || 'unknown').length <= 4, [doc?.language, gptPrompt?.expandedSelection]);

  return (
    <PaletteWrapper title="Choose Prompt">
      {shortSelection && shortSelectionPrompts.map((prompt: Prompt) =>
        <GptPaletteAction label={prompt.name} key={prompt.name} focused={false} action={promptAction(prompt, gptPrompt, highlight, doc)}>
          {prompt.name}
        </GptPaletteAction>)}

      {!shortSelection && longSelectionPrompts.map((prompt: Prompt) =>
        <GptPaletteAction label={prompt.name} key={prompt.name} focused={false} action={promptAction(prompt, gptPrompt, highlight, doc)}>
          {prompt.name}
        </GptPaletteAction>)}
    </PaletteWrapper>
  );
};

// Multi-line custom input
export const ManualGptPromptPalette = (): JSX.Element => {
  const highlightId = focusedHighlightIdState(useCallback((state) => state.focusedHighlightId, []));
  const [highlight] = useDocument<Highlight>(highlightId);
  const [doc] = useFocusedDocument();
  const gptPrompt = globalState(useCallback((state) => state.gptPrompt, []));

  return <PaletteWrapper multiline hasInput title="Enter your prompt" initialInput={gptPrompt?.prompt}>
    <GptPromptAction highlight={highlight} document={doc} useInput gptPrompt={gptPrompt} />
  </PaletteWrapper>;
};

// Fire the prompt, correcting if it's the Manual or Input palette!
export const GptPromptAction = (
  { highlight, gptPrompt, document, useInput }:
    { highlight?: Highlight | null; document: FirstClassDocument | null; gptPrompt: FullZustandState['gptPrompt']; useInput?: boolean; },
): JSX.Element => {
  const { input: inputPrompt } = useContext(CmdInputContext);

  const action = useMemo(() => async () => {
    let prompt = gptPrompt?.prompt;
    const systemPrompt = gptPrompt?.systemPrompt;

    if (inputPrompt) {
      // ManualPrompt (i.e. override prompt from text field)
      if (useInput) {
        prompt = setPrompt(inputPrompt, systemPrompt);
        // InputPrompt (i.e. substitute input() from text field)
      } else if (gptPrompt?.prompt?.match(INPUT_REGEX)) {
        prompt = setPrompt(gptPrompt.prompt.replace(INPUT_REGEX, `"${inputPrompt}"`), systemPrompt);
      }
    }

    addGptOutputToNote(document, gptPrompt, highlight, prompt, systemPrompt);
  }, [inputPrompt, highlight, document, gptPrompt, useInput]);

  return <PaletteAction
    focused
    action={action}
    shortcut={`${getActionKey()}+enter`}
  >
    Prompt
  </PaletteAction>;
};
