import type { KeyboardShortcut } from '../../types';
import { globalState } from '../models';

export default function executeKeyboardShortcut (keys: KeyboardShortcut['keys']) {
  const shortcutsFound = Object.values(globalState.getState().keyboardShortcuts)
    .filter((keyboardShortcut) => keyboardShortcut.keys === keys);

  if (!shortcutsFound.length) {
    throw new Error('No such shortcut those keys in state.keyboardShortcuts');
  }

  if (shortcutsFound.length > 1) {
    throw new Error('More than one shortcut with those keys in state.keyboardShortcuts');
  }

  // The fact it's keyup shouldn't matter. We also don't tell it which key was pressed.
  shortcutsFound[0].callback(new KeyboardEvent('keyup'));
}
