import React, { useMemo } from 'react';

import ttsController from '../../../../shared/foreground/actions/ttsController.platform';
import { setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import { FirstClassDocument, ShortcutId } from '../../../../shared/types';
import capitalize from '../../../../shared/utils/capitalize';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import { useShortcutsMap } from '../../utils/shortcuts';
import { PaletteAction } from './Base/PaletteAction';

export const TtsJumpAction = React.memo(function TtsJumpAction({
  direction,
  focused,
}: PaletteAction & {
  direction: 'backward' | 'forward';
}) {
  const shortcutsMap = useShortcutsMap();
  const shortcut = useMemo(
    () => shortcutsMap[direction === 'backward' ? ShortcutId.SkipBackwards : ShortcutId.SkipForward],
    [direction, shortcutsMap],
  );

  const action = async () => {
    await Promise.all([
      ttsController[`jump${capitalize(direction)}`](),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcut}>
    Jump {direction}s
  </PaletteAction>;
});

export const TtsResumeOrPauseAction = React.memo(function TtsResumeOrPauseAction({
  focused,
}: PaletteAction) {
  const shortcutsMap = useShortcutsMap();

  const action = async () => {
    await Promise.all([
      ttsController.resumeOrPauseCurrentlyPlayingDocument(),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcutsMap[ShortcutId.PlayOrPause]}>
    Pause / resume
  </PaletteAction>;
});

export const TtsPlayrateAction = React.memo(function TtsPlayrateAction({
  actionName,
  focused,
}: PaletteAction & {
  actionName: 'increase' | 'decrease';
}) {
  const shortcutsMap = useShortcutsMap();
  const shortcut = useMemo(
    () => shortcutsMap[actionName === 'increase' ? ShortcutId.SpeedUpPlayback : ShortcutId.SlowDownPlayBack],
    [actionName, shortcutsMap],
  );

  const action = async () => {
    const userInteraction = 'unknown';
    await Promise.all([
      ttsController[`${actionName}PlaybackRatePreference`]({ userInteraction }),
      setCmdPaletteOpen(false, { userInteraction }),
    ]);
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcut}>
    {capitalize(actionName)} playback rate
  </PaletteAction>;
});

export const TtsStartAction = React.memo(function TtsStartAction({
  doc,
  focused,
}: PaletteAction & {
  doc: FirstClassDocument;
}) {
  const action = async () => {
    await Promise.all([
      ttsController.playDocument(doc.id),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  };

  return <PaletteAction action={action} focused={focused}>
    Start for &quot;{getDocumentTitle(doc)}&quot;
  </PaletteAction>;
});

export const TtsStopAction = React.memo(function TtsStopAction({
  focused,
}: PaletteAction) {
  const shortcutsMap = useShortcutsMap();

  const action = async () => {
    await Promise.all([
      ttsController.stop(),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcutsMap[ShortcutId.Stop]}>
    Stop and hide player
  </PaletteAction>;
});

export const TtsVolumeAction = React.memo(function TtsVolumeAction({
  actionName,
  focused,
}: PaletteAction & {
  actionName: 'increase' | 'decrease';
}) {
  const shortcutsMap = useShortcutsMap();
  const shortcut = useMemo(
    () => shortcutsMap[actionName === 'increase' ? ShortcutId.IncreaseVolume : ShortcutId.DecreaseVolume],
    [actionName, shortcutsMap],
  );

  const action = async () => {
    const userInteraction = 'unknown';
    await Promise.all([
      ttsController.modifyVolumePreference(actionName, { userInteraction }),
      setCmdPaletteOpen(false, { userInteraction }),
    ]);
  };

  return <PaletteAction
    action={action}
    focused={focused}
    shortcut={shortcut}>
    {capitalize(actionName)} volume
  </PaletteAction>;
});

