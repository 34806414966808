import type { FirstClassDocument } from '../../types';
import { commitId, getPlatformInfo } from '../../utils/environment';
import { FeedbackCategory, FeedbackSubCategory } from '../../utils/feedback';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { getDocument } from '../stateGetters';
import { createToast } from '../toasts.platform';


export async function createLinearIssue(docId: FirstClassDocument['id'], description: string) {

  const doc = await getDocument(docId);

  if (!doc) {
    createToast({ category: 'error', content: `Document not found!` });
    return;
  }
  const extraDocData = `\\n\\nDocument url: ${doc.url}\\nDocument in admin: https://readwise.io/admin/reader/document/${docId}`;
  const payload = {
    description: description.replace(/\n/g, '\\n') + extraDocData,
    title: `Parsing glitch: ${docId}`,
  };
  try {
    await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/linear`,
      {
        body: JSON.stringify(payload), credentials: 'include', method: 'POST', mode: 'cors',
      },
    );
    createToast({ category: 'success', content: `Parsing error reported. Thanks!` });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    createToast({ category: 'error', content: `Oops! Failed to report parsing error (code: ${e.response?.status || 'unknown'})` });
  }
}

type Metadata = {
  platform: 'web' | 'mobile';
  device?: string;
  version?: number | string;
  appVersion?: string;
  url?: string;
  userAgentInfo?: string;
};

export async function createFeatureRequest(description: string, subCategory: FeedbackSubCategory | undefined, metadata: Metadata) {
  const payload = {
    description,
    subCategory,
    metadata: metadata || {},
  };
  try {
    await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/feature_request`,
      {
        body: JSON.stringify(payload), credentials: 'include', method: 'POST', mode: 'cors',
      },
    );
    createToast({ category: 'success', content: `Suggestion sent. Thanks!` });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    createToast({ category: 'error', content: `Oops! Failed to sent suggestion (code: ${e.response.status})` });
  }
}

export async function createBugReport(description: string, subCategory: FeedbackSubCategory | undefined, metadata: Metadata) {
  const payload = {
    description: `${description}\n\nAPP GIT COMMIT: ${commitId}`,
    subCategory,
    metadata: metadata || {},
  };
  const platformInfo = metadata?.platform === 'mobile' ? metadata.userAgentInfo : getPlatformInfo();
  payload.metadata.userAgentInfo = platformInfo;
  try {
    await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/bug_report`,
      {
        body: JSON.stringify(payload), credentials: 'include', method: 'POST', mode: 'cors',
      },
    );
    createToast({ category: 'success', content: `Bug reported. Thanks!` });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    createToast({ category: 'error', content: `Oops! Failed to report a bug (code: ${e.response?.status || 'unknown'})` });
  }
}

export async function createFeedbackRequest(description: string, category: FeedbackCategory, subCategory: FeedbackSubCategory | undefined, metadata: Metadata) {
  const payload = {
    description: `${description}\n\nAPP GIT COMMIT: ${commitId}`,
    category,
    subCategory,
    metadata: metadata || {},
  };

  const platformInfo = metadata?.platform === 'mobile' ? metadata.userAgentInfo : getPlatformInfo();
  payload.metadata.userAgentInfo = platformInfo;
  try {
    await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/feedback_request`,
      {
        body: JSON.stringify(payload), credentials: 'include', method: 'POST', mode: 'cors',
      },
    );
    createToast({ category: 'success', content: `Issue reported. Thanks!` });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    createToast({ category: 'error', content: `Oops! Failed to report issue (code: ${e.response?.status || 'unknown'})` });
  }
}


export async function markFeedAsPartial(docId: FirstClassDocument['id']) {
  const payload = {
    docId,
  };
  try {
    await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/report_partial_feed`,
      {
        body: JSON.stringify(payload), credentials: 'include', method: 'POST', mode: 'cors',
      },
    );
    createToast({ category: 'success', content: `Partial feed content reported` });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    createToast({ category: 'error', content: `Oops! Failed to report partial feed (code: ${e.response?.status || 'unknown'})` });
  }
}
