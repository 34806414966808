import Fuse from 'fuse.js';
import map from 'lodash/map';
import throttle from 'lodash/throttle';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

import {
  setCmdPaletteOpen,
  setCmdPaletteSubMenu,
  setCmdPaletteSubMenuParent,
} from '../../../../../shared/foreground/cmdPalette';
import { globalState } from '../../../../../shared/foreground/models';
import { getFeed, getView } from '../../../../../shared/foreground/stateGetters';
import useFocusedDocument from '../../../../../shared/foreground/stateHooks/useFocusedDocument';
import isComposing from '../../../../../shared/foreground/utils/isComposing';
import type { FilteredView, RssFeed } from '../../../../../shared/types';
import { MainTitleType, SubMenu } from '../../../../../shared/types';
import makeLogger from '../../../../../shared/utils/makeLogger';
import Button from '../../Button';
import Tooltip from '../../Tooltip';
import styles from '../CommandPalette.module.css';
// eslint-disable-next-line import/no-cycle
import { PaletteAction, PaletteGroup } from './PaletteAction';

const logger = makeLogger(__filename);

type PaletteWrapperTypes = {
  children: ReactNode;
  title: string;
  multiline?: boolean;
  hasInput?: boolean;
  initialInput?: string;
  className?: string;
  placeholder?: string;
  customComponent?: ReactNode;
  showRecordScreenButton?: boolean;
};

export type CmdAction = (() => void) | (() => Promise<void>);

const CmdActionContext = React.createContext(
    // eslint-disable-next-line
    (p: () => CmdAction) => {},
);

interface CmdInputContextState {
  input: string;
  setInput: (input: string) => void;
}

export const CmdInputContext = React.createContext({ input: '' } as CmdInputContextState);

export const useCmdAction = (actionFunc: CmdAction | undefined, focused: boolean, deps = []): void => {
  const setFocusedActionHandler = useContext(CmdActionContext);
  useEffect(() => {
    if (focused && actionFunc) {
      setFocusedActionHandler(() => actionFunc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, actionFunc, ...deps]);
};

type MouseFocusTracker = {
    index: number;
    setCurrentIndex: (i: number) => void;
    children: ReactNode;
};
const MouseFocusTracker = ({ index, setCurrentIndex, children }: MouseFocusTracker) => {
  const updateIndex = useCallback(() => {
    setCurrentIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);
  return <div onMouseMove={updateIndex}>{children}</div>;
};

const FuseSearch = (item: ActionChild, searchValue: string, keys: string[] = ['props.label', 'props.tags']) => {
  if (searchValue && searchValue !== '') {
    if (item.props.label && item.props.label !== '') {
      const fuse = new Fuse([item], {
        distance: 500,
        keys,
        location: 0,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        shouldSort: true,
        threshold: 0.2,
      } as Fuse.IFuseOptions<ActionChild>);
      return Array.from(map(fuse.search(searchValue), 'item'));
    } else {
      return [item];
    }
  }
  return [item];
};

type ActionChild = React.ReactPortal & PaletteAction;

const LOOM_PUBLIC_APP_ID = 'b197abe3-9d98-47b4-9135-747730dbb0b6';
const BUTTON_ID = 'loom-record-sdk-button';

const VideoRecorderButton = ({ onInsertClick }: {onInsertClick: (videoUrl: string) => void;}) => {
  const [hasLoomSetupFailed, setHasLoomSetupFailed] = useState(false);

  useEffect(() => {
    async function setupLoom() {
      const button = document.getElementById(BUTTON_ID);

      if (!button) {
        return;
      }

      let configureButton;
      try {
        const { setup } = await import('@loomhq/record-sdk');
        const setupResult = await setup({
          publicAppId: LOOM_PUBLIC_APP_ID,
        });
        configureButton = setupResult.configureButton;
      } catch (e) {
        setHasLoomSetupFailed(true);
        logger.error('error setting up loom', { e });
      }

      const sdkButton = configureButton({ element: button });

      sdkButton.on('insert-click', async (video: { sharedUrl: string; }) => {
        onInsertClick(video.sharedUrl);
      });

      sdkButton.on('recording-start', async () => {
        setCmdPaletteOpen(false, { userInteraction: 'keydown' });
      });
    }

    setupLoom();
  }, [onInsertClick]);

  if (hasLoomSetupFailed) {
    return null;
  }

  return (
    <Tooltip content="Record an embedded Loom (no installation required)." maxWidth={500}>
      <Button id={BUTTON_ID} className={styles.recordButton} variant="default">Record Loom</Button>
    </Tooltip>
  );
};

const VideoEmbeded = ({ videoUrl }: {videoUrl: string;}) => {
  const [videoHTML, setVideoHTML] = useState('');

  useEffect(() => {
    async function embedVideo() {
      const { oembed } = await import('@loomhq/loom-embed');
      const { html } = await oembed(videoUrl, { width: 400 });
      setVideoHTML(html);
    }

    embedVideo();
  }, [videoUrl]);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: videoHTML }} />
    </>
  );
};

export const PaletteWrapper = ({ title = 'Enter a command', placeholder = '', initialInput, children, multiline = false, showRecordScreenButton = false, hasInput = true, className = '', customComponent }: PaletteWrapperTypes): JSX.Element => {
  const [loomVideoUrl, setLoomVideoUrl] = useState('');
  const [isLoomSupported, setIsLoomSupported] = useState(false);

  // This is where the current context should be figured out?
  const [internalInputValue, setInternalInputValue] = useState(initialInput || '');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainTitle, setMainTitle] = useState('');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    async function checkLoomSupport() {
      let loomSdk;
      try {
        loomSdk = await import('@loomhq/record-sdk');
      } catch (e) {
        setIsLoomSupported(false);
        logger.error('error importing loom', { e });
        return;
      }
      const { supported } = await loomSdk.isSupported();
      if (supported) {
        setIsLoomSupported(true);
      }
    }

    if (showRecordScreenButton) {
      checkLoomSupport();
    }
  }, [showRecordScreenButton]);

  const [doc] = useFocusedDocument();
  const focusedFeedId = globalState(useCallback((state) => state.focusedFeedId, []));
  const focusedViewId = globalState(useCallback((state) => state.focusedViewId, []));

  const selectedFeedTitle = useMemo(() => {
    if (!focusedFeedId) {
      return '';
    }

    const feed = getFeed<RssFeed>(focusedFeedId);

    if (!feed) {
      return '';
    }

    return feed.name || '';

  }, [focusedFeedId]);

  const selectedViewTitle = useMemo(() => {
    if (!focusedViewId) {
      return '';
    }

    const view = getView<FilteredView>(focusedViewId);

    if (!view) {
      return '';
    }

    return view.name || '';

  }, [focusedViewId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateInputValue = useCallback(throttle(setInputValue, 50, { leading: false, trailing: true }), []);

  useEffect(() => {
    updateInputValue(internalInputValue);
    setCurrentIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalInputValue]);

  useEffect(() => {
    // Scroll the dropdown component to the right location if the selected tag is out of view
    const list = document.querySelector('.command-container') as HTMLElement;
    const selectedRow = document.querySelector('.palette-action-row.focused') as HTMLElement;
    if (selectedRow && list) {
      const top = selectedRow.getBoundingClientRect().top - list.getBoundingClientRect().top;
      if (top < 0) {
        // @ts-disable-next-line
        const dist = selectedRow.offsetTop - list.scrollTop;
        list.scrollTop += dist;
      }
      if (top + selectedRow.clientHeight > list.clientHeight) {
        // @ts-disable-next-line
        const dist = selectedRow.offsetTop - list.scrollTop - list.getBoundingClientRect().height + selectedRow.getBoundingClientRect().height;
        list.scrollTop += dist;
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    setInputValue('');
  }, []);
  // Filter the options based on input value
  // and other business logic
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [focusedActionHandler, setFocusedActionHandler] = useState(() => () => { });

  const [actions, count] = useMemo(() => {
    const cmds = React.Children.toArray(children) as ActionChild[];
    let i = 0;
    const _actions = [] as ReactNode[];
    cmds.forEach((cmd) => {
      if (cmd.type === PaletteGroup) {
        const cmdActions = React.Children.toArray(cmd.props.children) as ActionChild[];
        const childActions = [] as ReactNode[];
        cmdActions.forEach((cmdAction) => {
          if (FuseSearch(cmdAction, inputValue).length === 0) {
            return;
          }

          const focused = currentIndex === i;

          if (focused) {
            switch (cmdAction.props.mainTitleType) {
              case MainTitleType.FocusedArticle:
                setMainTitle(doc?.title || '');
                break;

              case MainTitleType.AllDocuments:
                setMainTitle('All Documents');
                break;

              case MainTitleType.AllDocumentsInList:
                setMainTitle('All Documents in List');
                break;

              case MainTitleType.AboveFocusedDoc:
                setMainTitle('Above Focused Document');
                break;

              case MainTitleType.BelowFocusedDoc:
                setMainTitle('Below Focused Document');
                break;

              case MainTitleType.Reader:
                setMainTitle('Reader Application');
                break;

              case MainTitleType.Rss:
                setMainTitle('Reader → RSS');
                break;

              case MainTitleType.EditFeed:
                setMainTitle(`Feed: ${selectedFeedTitle}`);
                break;

              case MainTitleType.EditFilteredView:
                setMainTitle(`Filtered view: ${selectedViewTitle}`);
                break;

              default:
                setMainTitle('');
                break;
            }
          }

          const a = <MouseFocusTracker
            key={`mouse-track-${cmdAction.props.uniqueId || cmdAction.props.label}`} index={i}
            setCurrentIndex={setCurrentIndex}>
             {
               React.cloneElement(cmdAction, {
                 focused,
               })
             }
           </MouseFocusTracker>;
          i += 1;
          childActions.push(a);
        });
        if (childActions.length > 0) {
          _actions.push(React.cloneElement(cmd, {
            children: childActions,
          }));
        }
      } else if (FuseSearch(cmd, inputValue).length > 0) {
        _actions.push(
            <MouseFocusTracker key={`mouse-track-${cmd.props.uniqueId || cmd.props.label}`} index={i} setCurrentIndex={setCurrentIndex}>
              {
                React.cloneElement(cmd, {
                  focused: currentIndex === i,
                })
              }
            </MouseFocusTracker>,
          );
        i += 1;
      }
    });

    if (_actions.length === 0) {
      setMainTitle('');
    }

    return [_actions, i];
  }, [children, inputValue, currentIndex, doc?.title, selectedFeedTitle, selectedViewTitle, setMainTitle]);

  const subMenu = globalState(useCallback((state) => state.cmdPalette.subMenu, []));
  const subMenuParent = globalState(useCallback((state) => state.cmdPalette.subMenuParent, []));

  const onKeyDown = useCallback((evt: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
    if (isComposing(evt)) {
      return;
    }
    const { key, keyCode } = evt;
    if (key === 'Escape') {
      evt.preventDefault();

      switch (subMenu) {
        // override 'esc' to go one level up in the following submenus
        case SubMenu.BugReport:
        case SubMenu.FeatureRequest:
        case SubMenu.FeedbackReport:
          setCmdPaletteSubMenu(subMenuParent ?? SubMenu.Normal, { userInteraction: 'keydown' });
          setCmdPaletteSubMenuParent(undefined, { userInteraction: 'keydown' });
          break;
        // close for all other cases
        default:
          setCmdPaletteOpen(false, { userInteraction: 'keydown' });
      }
    } else if (key === 'Enter') {
      // Gotta know what palette we are in
      if (!multiline || multiline && evt.metaKey || multiline && evt.ctrlKey) {
        if (count > 0) {
          evt.preventDefault();
          setInternalInputValue('');
          focusedActionHandler();
        }
      }
    } else if (keyCode === 38) {
      // Up code
      if (currentIndex > 0) {
        if (!multiline) {
          evt.preventDefault();
        }
        setCurrentIndex(currentIndex - 1);
      }
    } else if (keyCode === 40) {
      if (!multiline) {
        evt.preventDefault();
      }
      // Down Code
      if (currentIndex + 1 >= count) {
        setCurrentIndex(currentIndex);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }, [count, currentIndex, focusedActionHandler, multiline, subMenu, subMenuParent]);

  const cmdInputContextValue = useMemo(() => ({ input: inputValue.trim(), setInput: setInternalInputValue }), [inputValue, setInternalInputValue]);

  const _showRecordScreenButton = showRecordScreenButton && isLoomSupported;

  useEffect(() => {
    if (!window.loomVideoUrl) {
      return;
    }

    const loomVideoUrl = window.loomVideoUrl;
    setLoomVideoUrl(loomVideoUrl);
    setInternalInputValue((prevInput) => `${prevInput} ${loomVideoUrl}`);
    window.loomVideoUrl = undefined;

    return () => {
      window.loomVideoUrl = undefined;
    };
  }, []);

  const onVideoInsertClick = useCallback((url: string) => {
    window.loomVideoUrl = url;
    setCmdPaletteSubMenu(subMenu, { userInteraction: 'unknown' });
  }, [subMenu]);

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  return <div
    className={`${styles.palette} ${className ? className : ''}`}
    onClick={(error) => {
      error.preventDefault();
      error.stopPropagation();
    }}
    >
    {/* <h3 className={styles.title}>{title}</h3>*/}
    {hasInput && <div className={styles.inputContainer} style={loomVideoUrl ? { flexDirection: 'column' } : {}}>
      {_showRecordScreenButton && <VideoRecorderButton onInsertClick={onVideoInsertClick} />}
      {
        multiline
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          ? <textarea
              id="cp-input"
              value={internalInputValue}
              autoFocus
              className={`${styles.paletteInput} ${styles.paletteInputTextArea} ${_showRecordScreenButton ? styles.paletteInputTextAreaWithRecordButton : ''}`}
              onChange={(evt) => setInternalInputValue(evt.target.value)}
              onKeyDown={onKeyDown}
              rows={6}
              placeholder={placeholder || title}
          />
          : <>
            {/* eslint-disable-next-line @shopify/react-require-autocomplete, jsx-a11y/control-has-associated-label */}
            <input
              id="cp-input"
              value={internalInputValue}
              type="text"
              autoComplete="off"
              autoFocus
              className={styles.paletteInput}
              onChange={(evt) => setInternalInputValue(evt.target.value)}
              onKeyDown={onKeyDown}
              placeholder={placeholder || title}
            />
          </>
      }
      {!multiline && Boolean(internalInputValue) &&
        <div className={styles.clearWrapper}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <span
            className={styles.clearInput}
            onClick={() => {
              setInternalInputValue('');
            }}>Clear</span>
        </div>
      }
      {!multiline && <IoCloseOutline
        className={styles.closeIcon} onClick={() => {
          setCmdPaletteOpen(false, { userInteraction: 'click' });
        }}
      />}
      {Boolean(loomVideoUrl) && <VideoEmbeded videoUrl={loomVideoUrl} />}
    </div>
    }
    {Boolean(customComponent) && <div className={styles.customComponentContainer}>
      {customComponent}
    </div>
    }
    <CmdActionContext.Provider value={setFocusedActionHandler}>
      <CmdInputContext.Provider value={cmdInputContextValue}>
        {Boolean(mainTitle) && <h3 className={styles.sectionTitle}>{mainTitle}</h3>}
        <div className={`command-container ${styles.commandsContainer} has-visible-scrollbar ${mainTitle ? styles.withTitle : ''}`}>
          {actions}
        </div>
      </CmdInputContext.Provider>
    </CmdActionContext.Provider>
  </div>;
};
