import { createToast } from '../toasts.platform';
import { exportAllFiles } from './export';

export default async function exportFull(customToastText?: string) {
  await exportAllFiles();
  createToast({
    content: customToastText ? customToastText : 'Download requested, you will receive an email with the link to download.',
    category: 'success',
    duration: 5000,
  });
}
