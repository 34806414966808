import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { setCmdPaletteOpen } from '../../../../../shared/foreground/cmdPalette';
import type { InternalPaletteAction, PaletteAction as TPaletteAction } from './PaletteAction';
import { PaletteAction } from './PaletteAction';

export default function NavigationPaletteAction (
  {
    pathname,
    ...props
  }: {
    label: string;
    pathname: string;
  } & Pick<TPaletteAction, 'focused' | 'tags' | 'mainTitleType'>,
) {
  const history = useHistory();

  const action = useCallback(() => {
    setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    history.push(pathname);
  }, [history, pathname]);

  const args: Omit<InternalPaletteAction, 'children'> = useMemo(() => ({
    ...props,
    action,
  }), [action, props]);

  return <PaletteAction {...args}>
    {props.label}
  </PaletteAction>;
}
