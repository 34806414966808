import { ulid } from 'ulid';

import {
  AnyDocument,
  FullZustandState,
  Highlight,
  PartialDocument,
  RightSidebarVisiblePanel,
  SubMenu,
} from '../types';
import getServerBaseUrl from '../utils/getServerBaseUrl.platform';
import makeLogger from '../utils/makeLogger';
import requestWithAuth from '../utils/requestWithAuth.platformIncludingExtension';
import { openDocNoteSubMenu, setCmdPaletteOpen, setMobileSubmenuOpen } from './cmdPalette';
import eventEmitter from './eventEmitter';
import { updateState } from './models';
import background from './portalGates/toBackground/singleProcess';
import { getNoteFromHighlight } from './stateGetters';
import { saveDocumentNote } from './stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { saveNewDocument } from './stateUpdaters/persistentStateUpdaters/documents/create';
import { updateHighlight, updateHighlightNote } from './stateUpdaters/persistentStateUpdaters/documents/highlight';
import { setFocusedDocumentId, setFocusedHighlightId, setGPTPromptLoadingStatus } from './stateUpdaters/transientStateUpdaters/other';
import { hideRightSidebar } from './stateUpdaters/transientStateUpdaters/sidebars';
import { createToast } from './toasts.platform';

const logger = makeLogger(__filename);

export type Prompt = {
  name: string;
  target?: string;
  systemPrompt?: string;
  prompt: string;
};

export const prompts: { [P in string]: Prompt; } = {
  readerFaq: {
    name: 'Reader FAQ',
    prompt: [
      '{% set user_feedback = input("What do you need help with in Reader?") %}',
      '',
      'feedback: I\'m confused by the difference between Inbox and Feed',
      'question: What is the difference between Library and Feed?',
      '',
      'feedback: I want to be able to mark articles as seen as I scroll',
      'question: How do I mark items as seen while scrolling in Feed?',
      '',
      'feedback: Please provide the ability to add folders - (different reading sources).',
      'question: How do I use folders?',
      '',
      'feedback: The article parser doesn\'t seem to load when I am running my VPN. I continuously get the "Uh oh..." dialog when it is on. When I disconnect from the VPN, it works fine.',
      'question: How do I get the article parser to load when I am running my VPN?',
      '',
      'feedback: When I do a search on a term/phrase, and delete results from the search, the article stays in the list of results. If I then click on the context menu (...), the web app displays an empty popup. The article should disappear from the list when I delete it.',
      'question: How do I delete a document from the search results?',
      '',
      'feedback: Would love a way to see all the newsletters I\'m subscribed to in the Feeds tab.Right now, it only shows the subscribed RSS feeds.I manually subscribed to multiple newsletters using the Feed\'s custom email address, that don\'t show up in this list.',
      'question: How do I see the email newsletters I\'m subscribed to ? ',
      '',
      'feedback: Please bring the keyboard navigation from the web app to the iPad app',
      'question: How do I use keyboard shortcuts in the iPad app?',
      '',
      'feedback: The yellow highlight doesn\'t stand out enough',
      'question: How do I make the yellow highlight darker?',
      '',
      'feedback: Provide functionality to edit a highlight once it is made. For example, I might highlight a whole passage and then want to remove the last sentence. Or I might make a mistake with a highlight and want to adjust it afterwards.',
      'question: How do I edit an existing highlight to make it shorter or longer?',
      '',
      'feedback: Would love “shake to undo” on iOS.',
      'question: How do I undo an action on iOS?',
      '',
      'feedback: firefox extension',
      'question: How do I find the Reader Firefox extension?',
      '',
      'feedback: {{ user_feedback }}',
      'question:',
      '',
      '{% set user_question = response() %}',
      '',
      '',
      '{# ANSWER USER\'S QUESTION #}',
      '',
      '{# FEW SHOT - POSITIVE #}',
      '',
      'context: What\'s the difference between Library and Feed?',
      'Reader has two broad sections: Library and Feed.',
      'Library is further subdivided into Inbox, Later, and Archive.',
      'Inbox is where things that you manually curate for yourself go.',
      'Feed is where things that are automatically pushed to you go.',
      'As you find documents in Feed that you want to read later and/or permanently save, you can move them to your Library.',
      '',
      'question: What is the difference between Library and Feed?',
      '',
      'answer: Library is where things that you want to read later and/or permanently save go, and Feed is where things that are automatically pushed to you go.',
      '',
      '{# FEW SHOT - POSITIVE #}',
      '',
      'context: How do I mark items as seen simply while scrolling my Feed?',
      'Right now, Reader doesn\'t have a way to automatically mark items as seen as you scroll your Feed list like other RSS apps you may have used in the past.',
      'That said, there is a one-at-a-time, TikTok-like UI on mobile that will mark items as seen as you scroll them.',
      'In addition, you can customize your swipes on mobile to include an action that marks all items above as seen so you scroll and then once done mark all items you\'ve scrolled past with a single action.',
      '',
      'question: How do I mark items as seen while scrolling my Feed?',
      '',
      'answer: There is not currently a way to automatically mark items as seen as you scroll through your Feed list. However, you can customize your swipes on mobile to include an action that marks all items above as seen.',

      '',
      '{# FEW SHOT - POSITIVE #}',
      '',
      'context: If you save documents using the browser extensions on web or using the Safari browser and share sheet on iOS, these methods will generally result in the highest quality parsing because Reader is getting the full document content rather than the naked URL.',
      'If the document is still missing content (such as missing images), you should report those documents through the feedback section of the Reader app and selecting Report document parsing issue.',
      'We have an engineer dedicated to fixing parsing tickets and we are constantly upgrading our parsing.',
      '',
      'answer: If you save documents using the browser extensions on web or using the Safari browser and share sheet on iOS, these methods will generally result in the highest quality parsing because Reader is getting the full document content rather than the naked URL. If the document is still missing content (such as missing images), you should report those documents through the feedback section of the Reader app and selecting Report document parsing issue. We have an engineer dedicated to fixing parsing tickets and we are constantly upgrading our parsing.',
      '',
      'question: How do I fix article parsing errors?',
      '',
      '{# FEW SHOT - NEGATIVE #}',
      '',
      'context: In these rare cases, you can find the highlights in the Notebook tab of the Reader web app. ',
      'Any highlight you make on the native web using the browser extension will typically sync with Reader(and from Reader to Readwise) and vice versa.',
      'Sometimes highlights that I took using the browser extension do not appear overlaid in the Reader app.',
      'Finally, the Reader extension(yellow) is used to save documents to Reader and optionally enable highlighting of the open web.',
      'Every highlight you make in Reader instantly syncs with Readwise and then from Readwise to your note - taking apps.',
      '',
      'question: How do I get the article parser to load when I am running my VPN ?',
      '',
      'answer: The FAQ does not contain a good answer to this question.',
      '',
      '{# FEW SHOT - NEGATIVE # }',
      '',
      'context: In these rare cases, you can find the highlights in the Notebook tab of the Reader web app.',
      'Any highlight you make on the native web using the browser extension will typically sync with Reader(and from Reader to Readwise) and vice versa.',
      'Any highlight you make on the native web using the browser extension will typically sync with Reader(and from Reader to Readwise) and vice versa.',
      'Sometimes highlights that I took using the browser extension do not appear overlaid in the Reader app.',
      'Finally, the Reader extension(yellow) is used to save documents to Reader and optionally enable highlighting of the open web.',
      '',
      'question: How do I delete a document from the search results ?',
      '',
      'answer: The FAQ does not contain a good answer to this question.',
      '',
      '{# FEW SHOT - NEGATIVE # }',
      '',
      'context: How do I forward emails to Reader ?',
      'In these rare cases, you can find the highlights in the Notebook tab of the Reader web app.',
      'Any highlight you make on the native web using the browser extension will typically sync with Reader(and from Reader to Readwise) and vice versa.',
      'Any highlight you make on the native web using the browser extension will typically sync with Reader(and from Reader to Readwise) and vice versa.',
      'Sometimes highlights that I took using the browser extension do not appear overlaid in the Reader app.',
      '',
      'question: How do I forward emails to my account without the text being converted to italics ?',
      '',
      'answer: The FAQ does not contain a good answer to this question.',
      '',
      '{# PROMPT #}',
      '',
      'context: {{ document.content | most_similar(user_question, sentences_after=4, document_order=False, extractive_summary_result_threshold=None) | join("\\n") }}',
      '',
      'question: {{ user_question }}',
      '',
      'answer:',
    ].join('\n'),
  },

  define: {
    name: 'Define',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'I just came across the word or phrase "{{ selection }}" as used in the following sentence: "{{ selection.sentence }} "',
      '',
      'Please define "{{ selection }}" in the following format:',
      '{{ selection }} (part of speech): [short definition] [2 emojis]',
      '',
      'Here\'s an example of the format to use:',
      '',
      'abecedarian (adjective): rudimentary; elementary. 🧮🧒',
    ].join('\n'),
  },

  wikiLookup: {
    name: 'Encyclopedia Lookup',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'I just came across the word or phrase "{{ selection }}" as used in the following sentence: "{{ selection.sentence }}"',
      '',
      'Please write a very brief encyclopedia entry for "{{ selection }}".',
      '',
      'Here\'s an example of the length and tone to use:',
      'Unix philosophy: The philosophy that small, modular programs, which can be easily combined to perform complex tasks, are more effective and easier to maintain than large, monolithic programs.',
    ].join('\n'),
  },

  termLookup: {
    name: 'Term Lookup',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'I came across the term {{ selection }} while reading a document entitled "{{ document.title }}" as used in the following sentence: "{{ selection.sentence }}"',
      '',
      'I want to understand how the author is using the term "{{ selection }}" so I gathered sentences below which might be relevant:',
      '"""',
      '{{ document.content | most_similar(selection) | join("\\n\\n") | truncate(2500) }}',
      '{{ document.content | most_similar(selection.sentence) | join("\\n\\n") | truncate(2500) }}',
      '"""',
      '',
      'Based on the context above, please write a very brief description of how the author is using the term "{{ selection }}" in this document and add any details from your knowledge which might aid my understanding.',
      '',
      'Here\'s an example of the length and tone to use:\n',
      '',
      'Legibility: In this document, legibility refers to the ease with which a society can be controlled or manipulated by the state.',
    ].join('\n'),
  },

  translate: {
    name: 'Translate',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      '{% set query = input("What language do you want to translate to?") %}',
      '',
      'I just came across the word or phrase "{{ selection }}" as used in the following sentence: "{{ selection.sentence }}"',
      '',
      'Please translate "{{ selection }}" to {{ query }} in the following format:',
      '{{ selection }} (part of speech as used in the sentence): [{{ query }} equivalent] [1 emoji]',
      '',
      'Here\'s an example of the format as if the word "school" were used as a noun and you were asked to translate it from English to Spanish:',
      '',
      'school (noun): una escuela 🏫',
    ].join('\n'),
  },

  translateParagraph: {
    name: 'Translate',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      '{% set query = input("What language do you want to translate to?") %}',
      '',
      'Translate the passage below to {{ query }}:',
      '',
      '"""',
      '{{ selection }}',
      '"""',
    ].join('\n'),
  },

  tldr: {
    name: 'TL;DR',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'Summarize the following passage using only 8 words. tl;dr:',
      '"""',
      '{% if (selection | count_tokens) > 2500 %}',
      '{{ selection | central_sentences | join(\'\\n\\n\') }}',
      '{% else %}',
      '{{ selection }}',
      '{% endif %}',
      '"""',
    ].join('\n'),
  },

  haiku: {
    name: 'Haiku',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'Rewrite the following below into a haiku poem consisting of three lines, with five syllables in the first line, seven in the second, and five in the third. End each line with a descriptive emoji.',
      '',
      '"""',
      '{% if (selection | count_tokens) > 2500 %}',
      '{{ selection | central_sentences | join(\'\\n\\n\') }}',
      '{% else %}',
      '{{ selection }}',
      '{% endif %}',
      '"""',
    ].join('\n'),
  },

  flashcard: {
    name: 'Flashcard',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'While reading a document entitled "{{ document.title }}", I came across the following passage which I want to commit to memory using a question & answer flashcard: "{{ selection }}"',
      '',
      'Please rewrite the passage into a flashcard using the following format:',
      'Q: [question about the most salient concept]',
      'A: [answer to the question]',
      '',
      'Here\'s an example of the format to use:',
      '',
      'Q: What was the new way of thinking in the late 1960s that enabled efficient code production?',
      'A: The Unix philosophy, which suggests that small, modular programs, which can be easily combined to perform complex tasks, are more effective and easier to maintain than large, monolithic programs.',
    ].join('\n'),
  },

  artificialHighlightDoc: {
    name: 'Highlight the document',
    target: 'artificial-highlight-doc',
    prompt: '',
  },

  artificialTagDoc: {
    name: 'Tag the document',
    target: 'artificial-tag-doc',
    prompt: '',
  },

  summarizeDoc: {
    name: 'Summarize the document',
    prompt: [
      'Summarize the text below from the document entitled "{{ document.title }}":',
      '',
      '"""',
      '{% if (document.content | count_tokens) > 1000 %}',
      '{{ document.content | central_sentences | join(\'\\n\\n\') }}',
      '{% else %}',
      '{{ document.content }}',
      '{% endif %}',
      '"""',
    ].join('\n'),
  },

  summarizeDocManually: {
    name: 'Summarize the document',
    target: 'summarize-doc-manually',
    prompt: '',
  },

  summarizeDocAsHtml: {
    name: 'Summarize Document in new document',
    prompt: [
      'Summarize the text below from the document entitled "{{ document.title }}":',
      '',
      '"""',
      '{% if (document.content | count_tokens) > 1000 %}',
      '{{ document.content | central_sentences | join(\'\\n\\n\') }}',
      '{% else %}',
      '{{ document.content }}',
      '{% endif %}',
      '"""',
      '',
      'Summary formatted as HTML:',
    ].join('\n'),
  },

  simplifyDoc: {
    name: 'Simplify',
    prompt: [
      'My second grader asked me to simplify the document below entitled "{{ document.title }}":',
      '',
      'I extracted the key sentences from the document:',
      '',
      '"""',
      '{{ document.content | central_sentences | join(\'\\n\\n\') }}',
      '"""',
      '',
      'I rewrote the document using simple words a second grader would understand, formatted as HTML:',
    ].join('\n'),
  },

  simplify: {
    name: 'Simplify',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'I\'m reading a document entitled "{{ document.title }}" and my second grader asked me to rewrite a confusing passage into a simple paragraph using words and concepts that she would understand.',
      '',
      '{% if (document.content | length) > 0 %}',
      'In order to provide a better explanation, I found these relevant sentences from the same document:\n',
      '"""',
      '{{ document.content | most_similar(selection) | join(\'\\n\\n\') | truncate(2500) }}',
      '"""',
      '{% endif %}',
      '',
      'Rewrite the following passage into a single paragraph for her:',
      '"""',
      '{% if (selection | count_tokens) > 2500 %}',
      '{{ selection | central_sentences | join(\'\\n\\n\') }}',
      '{% else %}',
      '{{ selection }}',
      '{% endif %}',
      '"""',
    ].join('\n'),
  },

  question: {
    name: 'Ask the document a question',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      '{% set query = input("Ask a specific question likely to be contained in this document.") %}',
      '',
      'I\'m reading a document entitled "{{ document.title }}" and had the question: "{{ query }}?"',
      '',
      'Here\'s some sample text from the document to give you a feel for the subject matter and writing style:',
      '"""',
      '{{ document.content | central_sentences | join(\'\\n\\n\') | truncate(1000) }}',
      '"""',
      '',
      'Write a short sentence attempting to answer my question: "{{ query }}?"',
      '{%- set hallucination = response() -%}',
      '',
      'Ignore this: """{{ hallucination }}"""',
      '',
      'I\'m reading a document entitled "{{ document.title }}" and had the question: "{{ query }}?"',
      '',
      'I examined the document for passages that might be relevant:',
      '"""',
      '{{ document.content | most_similar(hallucination, document_order=True, sentences_before=2, sentences_after=2, extractive_summary_result_threshold=4, extractive_summary_score_threshold=0.5) | join("\\n\\n") | truncate(2500) }}',
      '"""',
      '',
      'Then try to answer my question using the information provided. If there is not enough information provided to answer confidently, do not try to answer. Instead let me know what the document is about.',
    ].join('\n'),
  },

  thoughtprovokingQuestions: {
    name: 'Generate thought-provoking questions',
    systemPrompt: [
      'You are a helpful reading assistant.',
    ].join('\n'),
    prompt: [
      'I\'m about to read a document entitled {{ document.title }}. Some of the key sections are extracted below:',
      '',
      '"""',
      '',
      '{%- if (document.content | count_tokens) > 2500 -%}',
      '{{ document.content | central_sentences | join("\\n\\n") }}',
      '{%- else -%}',
      '{{ document.content }}',
      '{%- endif -%}',
      '',
      '"""',
      '',
      'What are some interesting questions I should ask while reading this document? List no more than 3.',
    ].join('\n'),
  },

  qaFromHighlights: {
    name: 'Generate Q&A pairs based on your highlights',
    prompt: [
      'I just read a document entitled {{ document.title }}. Below are some key sentences:',
      '',
      '"""',
      '{% if (document.content | count_tokens) > 2500 %}',
      '{{ document.content | central_sentences | join("\\n\\n") }}',
      '{% else %}',
      '{{ document.content }}',
      '{% endif %}',
      '"""',
      '',
      'I took the following highlights while reading the document:',
      '"""',
      '{% if highlights | length %}',
      '{% for highlight in highlights %}',
      'highlight: {{ highlight.content }} {{ "\\n\\n" }}',
      '{% endfor %}',
      '{% else %}',
      'There were no highlights.',
      '{% endif %}',
      '"""',
      '',
      'Based on these highlights, write up to three question and answer pairs in the style of a flashcard to test my comprehension. If there were no highlights, respond "Take some highlights and try this prompt again."',
    ].join('\n\n'),
  },

  customSelection: {
    name: 'Custom',
    target: 'manual',
    prompt: [
      '{#- ⚠️ Custom prompts are an advanced feature. ⚠️',
      '',
      'Custom prompts enable you to interface with GPT-3 directly using variables for content contained in the document such as the title, the whole text, the focused paragraph, a text selection, your highlights, and more. This is done using the Jinja templating language.',
      '',
      'See reference documentation for more instructions on how to use custom Ghostreader prompts. The default example below writes a haiku based on your selection after checking to see if it\'s too long. -#}',
      '',
      'Below is text from {{ document.title }}:',
      '',
      '"""',
      '{% if (selection | count_tokens) > 1000 %}',
      '{{ selection | central_sentences | join("\\n\\n") }}',
      '{% else %}',
      '{{ selection }}',
      '{% endif %}',
      '"""',
      '',
      'Write a haiku.',
    ].join('\n'),
  },

  customDoc: {
    name: 'Custom',
    target: 'manual',
    prompt: [
      '{#- ⚠️ Custom prompts are an advanced feature. ⚠️',
      '',
      'Custom prompts enable you to interface with GPT-3 directly using variables for content contained in the document such as the title, the whole text, the focused paragraph, a text selection, your highlights, and more. This is done using the Jinja templating language.',
      '',
      'See reference documentation for more instructions on how to use custom Ghostreader prompts. The default example below writes a haiku based on your highlights. -#}',
      '',
      'I took the following highlights while reading a document titled {{ document.title }}:',
      '',
      '"""',
      '{% for highlight in highlights %}',
      '{{ highlight.content }} {{ "\\n\\n" }}',
      '{% endfor %}',
      '"""',
      '',
      'Write a haiku.',
    ].join('\n'),
  },
};

export const longSelectionPrompts = [
  prompts.simplify,
  prompts.translateParagraph,
  prompts.tldr,
  prompts.haiku,
  prompts.flashcard,
  prompts.customSelection,
];

export const longSelectionPromptsMobile = [
  prompts.simplify,
  prompts.translateParagraph,
  prompts.tldr,
  prompts.haiku,
  prompts.flashcard,
];

export const shortSelectionPrompts = [
  prompts.define,
  prompts.wikiLookup,
  prompts.termLookup,
  prompts.translate,
  prompts.customSelection,
];

export const shortSelectionPromptsMobile = [
  prompts.define,
  prompts.wikiLookup,
  prompts.termLookup,
  prompts.translate,
];

export const documentPrompts = [
  prompts.question,
  prompts.summarizeDocManually,
  prompts.artificialHighlightDoc,
  prompts.artificialTagDoc,
  prompts.thoughtprovokingQuestions,
  prompts.qaFromHighlights,
  prompts.customDoc,
];

export const documentPromptsMobile = [
  prompts.question,
  prompts.summarizeDocManually,
  prompts.artificialHighlightDoc,
  prompts.thoughtprovokingQuestions,
  prompts.qaFromHighlights,
];

export const addGptOutputToNote = async (
  doc: AnyDocument | PartialDocument<AnyDocument, 'id' | 'title' | 'notes'> | null | void,
  gptPrompt: FullZustandState['gptPrompt'],
  highlight?: Highlight | PartialDocument<Highlight, 'id' | 'parent' | 'source_specific_data' | 'children'> | null | void,
  overridePrompt?: string,
  overrideSystemPrompt?: string,
) => {
  if (!doc) {
    throw new Error('No document');
  }
  await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  const templatedPrompt = overridePrompt ?? gptPrompt?.prompt;
  const systemPrompt = overrideSystemPrompt ?? gptPrompt?.systemPrompt;

  if (!templatedPrompt) {
    throw new Error('No prompt found');
  }

  setGPTPromptLoadingStatus(true);
  if (!highlight) {
    setMobileSubmenuOpen(SubMenu.DocNote, true);
  } else {
    setFocusedHighlightId(highlight.id);
    setMobileSubmenuOpen(SubMenu.HighlightNote, true);
  }

  const resp = await requestWithAuth(
    `${getServerBaseUrl()}/reader/api/completion/`,
    {
      body: JSON.stringify({
        template: {
          prompt: templatedPrompt,
          systemPrompt,
          selection: gptPrompt?.selection,
          expandedSelection: gptPrompt?.expandedSelection,
          focusIndicator: gptPrompt?.focusIndicatorContents,
          documentId: highlight?.parent ?? doc?.id,
        },
      }),
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
    },
  );


  setGPTPromptLoadingStatus(false);
  if (resp.ok) {
    const response = await resp.json();
    logger.debug(response);

    if (response.warnings) {
      for (const warning of response.warnings) {
        createToast({
          content: warning,
          category: 'warning',
        });
      }
    }

    if (highlight) {
      const oldNote = await getNoteFromHighlight(highlight);
      const noteInput = [response.gpt3.trim(), oldNote.trim()]
        .filter((note) => Boolean(note))
        .join('\n\n');
      await updateHighlightNote(
        highlight,
        noteInput,
        {
          isUndoable: false,
          userInteraction: 'gpt',
        },
      );
      createToast({
        content: 'GPT added to highlight',
        category: 'success',
      });
      const oldData = highlight.source_specific_data;
      await updateHighlight(highlight.id, {
        source_specific_data: {
          ...oldData,
          ghostreader: {
            templatedPrompt,
            renderedPrompt: response.prompt,
          },
        },
      });

      eventEmitter.emit('ghostreader:highlight', highlight.id);
    } else if (gptPrompt?.newDocument) {
      const gptHtml = response.gpt3.trim();
      await saveNewDocument({
        author: 'Ghostreader',
        html: gptHtml, //  markdown to html
        source: 'Reader Ghostreader',
        title: `Ghostreader: ${doc.title}`,
        url: `https://ghostreader.ai/${ulid().toLowerCase()}`,
      }, { userInteraction: 'ghostreader' });
      background.pollLatestStateAndContent(20);
    } else {
      let newNoteContents = `${response.gpt3.trim()}`;
      if (doc.notes) {
        newNoteContents += `\n\n${doc.notes}`;
      }
      saveDocumentNote(doc.id, newNoteContents, { userInteraction: 'gpt' });
      hideRightSidebar(false, { userInteraction: 'click' });
      eventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);
      createToast({
        content: 'GPT added to document note',
        category: 'success',
        buttonText: 'View',
        onButtonClick: () => {
          setFocusedDocumentId(doc.id, { userInteraction: null });
          openDocNoteSubMenu();
        },
      });
    }
  } else {
    createToast({
      content: 'GPT failed, see network response',
      category: 'error',
    });
  }

  await updateState((state) => { // Free up memory
    state.gptPrompt = null;
  }, { eventName: 'gpt-prompt', userInteraction: 'unknown' });
};
